<template>
    <v-dialog v-model="show" width="400">
        <v-card v-if="purchase">
            <v-card-title class="subtitle-2">메모</v-card-title>
            <v-card-text>
                <v-text-field v-model="purchase.note" placeholder=" " hide-details/>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer/>
                <v-btn color="grey" text @click="close">취소</v-btn>
                <v-btn color="primary" text @click="save">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,
            purchase: null
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        async save(){
            await api.console.gshop.purchases.put(this.purchase);
			alert("메모가 등록되었습니다.")
			this.$emit("init")
            this.close();
        }
    }
}
</script>