<template>
    <v-dialog v-model="shows" width="400" :persistent="loading">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card outlined>
            <v-card-title>프롬프트 분류 설정</v-card-title>
            <v-card-text>
                <v-chip-group column>
                    <draggable v-model="form.promptGroups">
                        <template v-for="(item, index) in form.promptGroups">
                            <v-chip :key="item" outlined label close @click:close="form.promptGroups.splice(index, 1)"> {{ item }} </v-chip>
                        </template>
                    </draggable>
                </v-chip-group>
                <v-text-field v-model="text" v-bind="attrs_input" append-icon="mdi-plus" @click:append="add" @keydown.enter="add"> </v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="red" @click="shows = false">취소하기</v-btn>
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initSetting } from "@/assets/variables";

import Draggable from "vuedraggable";

export default {
    components: {
        Draggable,
    },
    props: {
        value: { type: Object, default: initSetting },
    },
    data: () => ({
        form: initSetting(),

        text: null,
        shows: false,
        loading: false,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initSetting(this.value);
        },
        add() {
            try {
                if (this.form.promptGroups.includes(this.text)) {
                    throw new Error("이미 추가된 항목입니다");
                }

                this.form.promptGroups.push(this.text);
                this.text = null;
            } catch (error) {
                alert(error.message);
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { promptGroups } = this.form;
                const { setting } = await api.console.setting.putSetting({ promptGroups });
                this.shows = false;
                this.$emit("input", setting);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>