<template>
    <v-container v-if="ready" fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">회원등급</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0"></v-col>
                </v-row>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">고객등급 수정</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="auto" class="subtitle-2">적용기간</v-col>
                            <v-col class="py-0 py-sm-3">
                                <span class="font-weight-bold">{{ nextScheduledDate }} ~ </span>
                                <span class="caption">(매월 1일 등급 산정 후 자동 적용)</span>
                                <br>
                                <span class="caption primary--text">설정된 등급은 다음달 1일부터 적용되며, 별도 설정을 변경하지 않으실 경우, 매월 1일 정오 전까지 등급 산정이 완료되어 자동으로 적용됩니다</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="auto" class="subtitle-2">등급기준</v-col>
                            <v-col class="py-0 py-sm-3">
                                <v-radio-group v-model="setting.users.levelStandard" row class="pa-0 ma-0" dense hide-details @change="saveSetting">
                                    <v-radio label="상품구매금액" value="price"></v-radio>
                                    <v-radio label="주문횟수" value="count"></v-radio>
                                </v-radio-group>
                                <v-divider class="my-4"></v-divider>
                                <v-radio-group v-model="setting.users.levelPeriod" row class="pa-0 ma-0 d-inline-block" dense hide-details @change="saveSetting">
                                    <v-radio label="전체" :value="0"></v-radio>
                                    <v-radio label="1개월" :value="1"></v-radio>
                                    <v-radio label="3개월" :value="3"></v-radio>
                                    <v-radio label="6개월" :value="6"></v-radio>
                                    <v-radio label="1년" :value="12"></v-radio>
                                </v-radio-group>
                                <span class="caption d-block d-sm-inline-block">동안 구매확정한 주문건</span>
                                <span class="caption primary--text mt-2 d-block">이번달 말일까지 구매확정된 거래 기준입니다.</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="auto" class="subtitle-2">가입등급</v-col>
                            <v-col cols="12" sm="5" class="py-0 py-sm-3">
                                <v-select v-model="setting.users._level" :items="levels" item-text="name" item-value="_id" label="신규회원가입 기본등급" persistent-placeholder @change="saveSetting"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-data-table :headers="levelsHeaders" :items="levels" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.schedule="{ item }">
                        <v-row align="center" justify="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.schedule.enabled" dense hide-details class="ma-0" @change="save(item)"></v-switch>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt.toDateTime() }}
                    </template>
                    <template v-slot:item.condition="{ item }">
                        <div v-if="item.schedule.enabled">
                            <span v-if="setting.users.levelStandard == 'price'">{{ schedulePrices.find((info) => info.value == item.schedule.price).text }}</span>
                            <span v-if="setting.users.levelStandard == 'count'">{{ scheduleCounts.find((info) => info.value == item.schedule.count).text }}</span>
                        </div>
                    </template>
                    <template v-slot:item.content="{ item }">
                        <v-row>
                            <v-col cols="12" sm="6" v-if="item.joinPoint.enabled" class="text-start">
                                <div class="subtitle-2 font-weight-bold">· 신규회원 포인트 지급</div>
                                <div class="body-2">- 지급포인트 {{ item.joinPoint.amount.format() }}{{ item.joinPoint.unit }}</div>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="item.purchasePoint.enabled" class="text-start">
                                <div class="subtitle-2 font-weight-bold">· 구매확정 포인트</div>
                                <div class="body-2">- 지급포인트 {{ item.purchasePoint.amount.format() }}{{ item.purchasePoint.unit }}</div>

                            </v-col>
                            <v-col cols="12" sm="6" v-if="item.joinCoupon.enabled" class="text-start">
                                <div class="subtitle-2 font-weight-bold">· 신규회원 쿠폰 발급</div>
                                <div class="body-2">- 할인 {{ item.joinCoupon.amount.format() }}{{ item.joinCoupon.unit }}</div>
                                <div class="body-2" v-if="item.joinCoupon.unit == '%' && item.joinCoupon.maxPrice">- 최대 {{ item.joinCoupon.maxPrice.format() }}원 할인</div>
                                <div class="body-2">- 수량 {{ item.joinCoupon.count.format() }}개</div>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="item.monthlyCoupon.enabled" class="text-start">
                                <div class="subtitle-2 font-weight-bold">· 정기 쿠폰 발급</div>
                                <div class="body-2">- 할인 {{ item.monthlyCoupon.amount.format() }}{{ item.monthlyCoupon.unit }}</div>
                                <div class="body-2" v-if="item.monthlyCoupon.unit == '%' && item.monthlyCoupon.maxPrice">- 최대 {{ item.monthlyCoupon.maxPrice.format() }}원 할인</div>
                                <div class="body-2">- 수량 {{ item.monthlyCoupon.count.format() }}개</div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon v-if="item.removable" small @click="remove(item)" class="ml-1">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <span v-if="editItem._id">{{ editItem.name }} 수정</span>
                            <span v-else>신규등급</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-select v-if="setting.users.levelStandard == 'price'" v-model="editItem.schedule.price" :disabled="!editItem.schedule.enabled" :items="schedulePrices" item-text="text" item-value="value" label="등급조건" persistent-placeholder dense hide-details></v-select>
                                    <v-select v-if="setting.users.levelStandard == 'count'" v-model="editItem.schedule.count" :disabled="!editItem.schedule.enabled" :items="scheduleCounts" item-text="text" item-value="value" label="등급조건" persistent-placeholder dense hide-details></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.rank" label="등급순위" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.code" label="등급코드" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.name" label="등급명칭" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-tabs background-color="white" class="mt-4">
                                <v-tab key="point">포인트</v-tab>
                                <v-tab key="coupon">쿠폰</v-tab>

                                <v-tab-item key="point">
                                    <v-row align="center">
                                        <v-col cols="auto" class="font-weight-bold">포인트 적립</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto">
                                            <v-switch v-model="editItem.purchasePoint.enabled" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.purchasePoint.enabled" align="center">
                                        <v-col class="pr-0">
                                            <v-text-field v-model="editItem.purchasePoint.amount" label="적립금액(%)" persistent-placeholder dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="pl-0">% 지급</v-col>
                                    </v-row>
                                    <v-row v-if="editItem.purchasePoint.enabled" align="center">
                                        <v-col class="pt-0">
                                            <span class="primary--text caption">상품 구매 후 구매확정 시 포인트가 추가 적립됩니다.<br>재구매혜택과 중복되지 않습니다. (재구매혜택보다 낮지 않게 설정해 주세요.)</span>
                                        </v-col>
                                    </v-row>

                                    <v-divider></v-divider>

                                    <v-row align="center">
                                        <v-col cols="auto" class="font-weight-bold">신규회원 포인트 지급</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto">
                                            <v-switch v-model="editItem.joinPoint.enabled" :disabled="setting.users._level != editItem._id" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.joinPoint.enabled" align="center" class="mt-6">
                                        <v-col class="py-0 pr-0">
                                            <v-text-field v-model="editItem.joinPoint.amount" label="적립금액(원)" persistent-placeholder dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="py-0 pl-0">원 지급</v-col>
                                    </v-row>
                                    <v-row v-if="editItem.joinPoint.enabled" align="center">
                                        <v-col class="pb-0">
                                            <span class="primary--text caption">해당 등급으로 신규가입시 포인트를 지급합니다.</span>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>

                                <v-tab-item key="coupon">
                                    <v-row align="center">
                                        <v-col cols="auto" class="font-weight-bold">정기 쿠폰 발급</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto">
                                            <v-switch v-model="editItem.monthlyCoupon.enabled" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.monthlyCoupon.enabled" align="center" class="mt-4">
                                        <v-col cols="12" md="6" class="py-0">
                                            <v-row justify="center" align="center">
                                                <v-col cols="8" class="py-0 pr-0">
                                                    <v-text-field v-model="editItem.monthlyCoupon.amount" label="할인금액" persistent-placeholder dense hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-select v-model="editItem.monthlyCoupon.unit" label="단위" persistent-placeholder :items="amountUnits" dense hide-details></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="editItem.monthlyCoupon.unit == '%'" cols="12" md="6" class="py-0">
                                            <v-row align="center">
                                                <v-col cols="auto" class="py-0 pr-0">최대</v-col>
                                                <v-col class="pa-0">
                                                    <v-text-field v-model="editItem.monthlyCoupon.maxPrice" dense hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="auto" class="py-0 pl-0">원 할인</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.monthlyCoupon.enabled" align="center" class="mt-4">
                                        <v-col class="py-0 pr-0"><v-text-field v-model="editItem.monthlyCoupon.count" label="쿠폰발급수량" persistent-placeholder dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0 pl-0">개</v-col>
                                    </v-row>
                                    <v-row v-if="editItem.monthlyCoupon.enabled" align="center">
                                        <v-col class="pt-1">
                                            <span class="primary--text caption">매월 1일 자동으로 쿠폰이 즉시 발급됩니다.(쿠폰 유효기간: 해당월 1일~말일)</span>
                                        </v-col>
                                    </v-row>

                                    <v-divider></v-divider>

                                    <v-row align="center">
                                        <v-col cols="auto" class="font-weight-bold">신규회원 쿠폰 발급</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto">
                                            <v-switch v-model="editItem.joinCoupon.enabled" :disabled="setting.users._level != editItem._id" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.joinCoupon.enabled" align="center" class="mt-4">
                                        <v-col cols="12" md="6" class="py-0">
                                            <v-row justify="center" align="center">
                                                <v-col cols="8" class="py-0 pr-0">
                                                    <v-text-field v-model="editItem.joinCoupon.amount" label="할인금액" persistent-placeholder dense hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-select v-model="editItem.joinCoupon.unit" :items="amountUnits" label="단위" persistent-placeholder dense hide-details></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="editItem.joinCoupon.unit == '%'" cols="12" md="6" class="py-0">
                                            <v-row align="center">
                                                <v-col cols="auto" class="py-0 pr-0">최대</v-col>
                                                <v-col class="pa-0">
                                                    <v-text-field v-model="editItem.joinCoupon.maxPrice" dense hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="auto" class="py-0 pl-0">원 할인</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editItem.joinCoupon.enabled" align="center" class="mt-4">
                                        <v-col class="py-0"><v-text-field v-model="editItem.joinCoupon.count" label="쿠폰발급수량" persistent-placeholder dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0 pl-0">개</v-col>
                                    </v-row>
                                    <v-row v-if="editItem.joinCoupon.enabled" align="center">
                                        <v-col class="pt-1">
                                            <span class="primary--text caption">매월 1일 자동으로 쿠폰이 즉시 발급됩니다.(쿠폰 유효기간: 해당월 1일~말일)</span>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey" text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
export default {
    data() {
        return {
            ready: false,
            dialog: false,

            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: false,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
            },

            // 설정정보
            setting: null,

            ///////////////////////////////////////////////
            // 회원등급 테이블 정보
            ///////////////////////////////////////////////
            limit: 20,
            page: 1,
            pageCount: 0,

            levels: [],
            levelsHeaders: [{
                text: '등급순위',
                align: 'center',
                width: 100,
                value: 'rank'
            }, {
                text: '코드',
                align: 'center',
                width: 140,
                value: 'code'
            }, {
                text: '명칭',
                width: 140,
                align: 'center',
                value: 'name'
            }, {
                text: '등급조건',
                align: 'center',
                width: 200,
                value: "condition"
            }, {
                text: '적용혜택',
                align: 'center',
                value: "content"
            }, {
                text: '적용기간',
                align: 'center',
                width: 100,
                value: 'schedule'
            }, {
                text: 'Actions',
                align: 'center',
                width: 100,
                value: 'actions'
            }],

            ////////////////////////////////////////////////////////////////////
            // /src/models/users/level.js 참고
            ////////////////////////////////////////////////////////////////////
            editItem: null,
            defaultItem: {
                _id: null,
                rank: 999,
                code: "",
                name: "",
                console: {
                    auth: false
                },
                client: {
                    auth: false
                },
                schedule: {
                    enabled: false,
                    price: 0,
                    count: 0,
                },
                joinPoint: {
                    enabled: false,
                    unit: '원',
                    amount: 0
                },
                purchasePoint: {
                    enabled: false,
                    unit: '%',
                    amount: 0
                },
                joinCoupon: {
                    enabled: false,
                    unit: '원',
                    amount: 0,
                    maxPrice: 0,
                    count: 0
                },
                monthlyCoupon: {
                    enabled: false,
                    unit: '원',
                    amount: 0,
                    maxPrice: 0,
                    count: 0
                }
            },

            schedulePrices: [
                { text: "구매없음", value: 0 },
                { text: "5만원이상", value: 50000 },
                { text: "10만원이상", value: 100000 },
                { text: "20만원이상", value: 200000 },
                { text: "30만원이상", value: 300000 },
                { text: "50만원이상", value: 500000 },
                { text: "100만원이상", value: 1000000 },
            ],

            scheduleCounts: [
                { text: "구매없음", value: 0 },
                { text: "구매1회이상", value: 1 },
                { text: "구매2회이상", value: 2 },
                { text: "구매3회이상", value: 3 },
                { text: "구매5회이상", value: 5 },
                { text: "구매10회이상", value: 10 },
                { text: "구매20회이상", value: 20 },
                { text: "구매30회이상", value: 30 },
                { text: "구매50회이상", value: 50 },
            ],

            amountUnits: [ "원", "%" ]
        };
    },
    created(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { setting } = await api.console.setting.get();
                this.setting = setting;

                await this.search();

                this.editItem = Object.assign({}, this.defaultItem);
                this.ready = true;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, levels } = await api.console.levels.getLevels({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.levels = levels;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.defaultItem.rank = this.lastRank + 1;
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },
        edit(level){
            this.editItem = level;
            this.dialog = true;
        },
        async remove(level){
            try{
                if(confirm("해당 등급을 삭제하시겠습니까?")){
                    await api.console.levels.deleteLevel(level);
                    await this.search();
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        close(){
            this.dialog = false;
        },

        check(level){
            try{
                if(this.setting.users.levelStandard == "price" && level.schedule.enabled && !level.schedule.price) throw new Error("등급조건을 선택해주세요");
                if(this.setting.users.levelStandard == "count" && level.schedule.enabled && !level.schedule.count) throw new Error("등급조건을 선택해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(level){
            try{
                if(this.check(level)){
                    level._id ? await api.console.levels.putLevel(level) : await api.console.levels.postLevel(level);
                    await this.search();
                    this.dialog = false;
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async saveSetting(){
            try{
                await api.console.setting.putSetting(this.setting);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        //////////////////////////////////////////////////////
        // 회원등급 변경 예정일자
        //////////////////////////////////////////////////////
        nextScheduledDate(){
            var date = new Date();
            date.addMonth(1);
            date.setDate(1);
            return date.getTime().toDate();
        },
        //////////////////////////////////////////////////////
        // 가장 낮은 회원등급 순위
        //////////////////////////////////////////////////////
        lastRank(){
            var rank = 0;
            for(var i in this.levels){
                if(rank < this.levels[i].rank) rank = this.levels[i].rank;
            }
            return rank;
        }
    }
}
</script>
