<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ title }} 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details clearable type="date" />
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details clearable type="date" />
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder />
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="search()" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <template v-if="$route.query.code == 'youtube'">
                <v-row class="mb-n4">
                    <v-col cols="auto">
                        <youtube-categories-list />
                    </v-col>
                </v-row>
            </template>
            <template v-if="$route.query.code == 'report'">
                <v-row class="mb-n4">
                    <v-col cols="auto">
                        <report-list-categories />
                    </v-col>
                </v-row>
            </template>
            <template v-if="$route.query.code == 'forms'">
                <v-row class="mb-n4">
                    <v-col cols="auto">
                        <forms-list-categories />
                    </v-col>
                </v-row>
            </template>

            <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row>
                        <v-img v-if="item.thumb" :src="item.thumb" width="54" height="54" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.meta.href`]="{ value }">
                    <v-btn v-if="!!value" icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <template v-else>-</template>
                </template>
                <template #[`item.meta.youtube`]="{ value }">
                    <v-btn icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import { BOARD_FORMS_CATEGORIES, BOARD_REPORT_CATEGORIES, BOARD_YOUTUBE_CATEGORIES } from "@/assets/variables";

export default {
    components: {
        VDateField,
    },
    prop: {
        tab: String,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            limit: 10,

            boards: [],
            summary: { totalCount: 0 },

            searchKey: null,
            searchValue: null,
        };
    },
    computed: {
        title() {
            if (this.$route.query.code == "consumer-notice-board") return "준회원 공지사항";
            if (this.$route.query.code == "promoter-notice-board") return "정회원 공지사항";
            if (this.$route.query.code == "lecture-qna") return "온디맨드 Q&A";
            if (this.$route.query.code == "lecture-review") return "온디맨드 Review";
            return "게시글";
        },
        categories() {
            if (this.$route.query.code == "youtube") return BOARD_YOUTUBE_CATEGORIES;
            if (this.$route.query.code == "report") return BOARD_REPORT_CATEGORIES;
            if (this.$route.query.code == "forms") return BOARD_FORMS_CATEGORIES;
            return {};
        },
        headers() {
            return [
                { width: 40, text: "no", value: "index", align: "right", divider: true },
                { width: 80, text: "썸네일", value: "thumb", test: (code) => !["consumer-notice-board","promoter-notice-board", "story-dentium-notice", "story-ir-notice", "story-ir-data", "story-ir-analysis", "lecture-qna", "lecture-review", "digital-center-library"].includes(code), divider: true },
                { width: 50, text: "유튜브", value: "meta.youtube", test: (code) => ["youtube"].includes(code) },
                { width: 120, text: "링크", value: "meta.href", test: (code) => ["press", "main-gallery"].includes(code), divider: true },
                { width: 100, text: "기간", test: (code) => ["store", "event"].includes(code), value: "period" },
                { width: 180, text: "카테고리", value: "category", test: (code) => !["consumer-notice-board","promoter-notice-board", "digital-center-library", "story-dentium-notice", "story-ir-notice", "story-ir-data", "story-ir-analysis", "lecture-qna", "lecture-review", "main-gallery"].includes(code), formatter: (value) => this.categories[value]?.text || value || "-", divider: true },
                { width: 180, text: "강좌명", value: "meta.lecture.name", test: (code) => ["lecture-qna", "lecture-review"].includes(code), divider: true },
                { text: "제목", value: "subject", divider: true },
                { width: 160, text: "작성자", value: "writer.name", test: (code) => !["story-dentium-notice", "story-ir-notice", "story-ir-data", "story-ir-analysis"].includes(code), divider: true },
                { width: 170, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", divider: true },
                { width: 80, text: "답변", value: "meta.isReplied", test: (code) => ["lecture-qna"].includes(code), formatter: (value) => (value ? "완료" : "대기"), align: "center", divider: true },
                { width: 80, value: "actions", align: "right" },
            ].filter((header) => (header.test ? header.test(this.$route.query.code) : true));
        },
        items() {
            return this.boards.map((board, index) => ({ ...board, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
        searchKeys() {
            return [
                { text: "전체", value: null },
                { text: "강좌명", value: "meta.lecture.name", hides: !["lecture-qna", "lecture-review"].includes(this.filter.code) },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ].filter(({ hides }) => !hides);
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, boards } = await api.console.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/dhbizadm/boards/create?code=${this.$route.query.code}`);
        },
        edit(board) {
            this.$router.push(`/dhbizadm/boards/${board._id}`);
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.boards.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
