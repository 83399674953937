var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("포인트 충전")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "금액",
      "type": "number",
      "placeholder": " ",
      "hint": (+_vm.cash.price || 0).format(),
      "persistent-hint": ""
    },
    model: {
      value: _vm.cash.price,
      callback: function ($$v) {
        _vm.$set(_vm.cash, "price", $$v);
      },
      expression: "cash.price"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "비고",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.cash.remark,
      callback: function ($$v) {
        _vm.$set(_vm.cash, "remark", $$v);
      },
      expression: "cash.remark"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("확인")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }