<template>
    <v-layout justify-center>
        <v-responsive max-width="1024" width="100%" height="100%">
            <v-row class="mt-4 px-1" align="center">
                <v-col cols="auto" class="tit tit--lg">IP 블랙 리스트</v-col>
                <v-spacer/>
                <v-col cols="auto" class="py-0">
                    <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                        <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                    </v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pb-0">
                        <v-row align="center">
                            <v-col cols="12" sm="4">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null;"></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true);"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search(true);">
                            <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="ipItems" :headers="ipItemsHeaders" hide-default-footer disable-sort class="mt-3 mx-1 elevation-1">
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    <v-icon small class="ml-1" @click="remove(item)">mdi-delete</v-icon>
                </template>
                <template #[`item.createdAt`]="{item}">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
            </v-data-table>

            <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

            <v-btn color="accent" fab fixed bottom right dark @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                code: "black"
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "name" },
                { text: "IP", value: "ip" },
                { text: "연락처", value: "phone" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            ipItems: [],
            ipItemsHeaders: [
                { text: "이름", value: "name", align: "center" },
                { text: "IP", value: "ip", align: "center" },
                { text: "연락처", value: "phone", align: "center" },
                { text: "사유", value: "reason", align: "center" },
                { text: "생성일자", value: "createdAt", align: "center", width: 200 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },

        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, ipItems } = await api.console.ip.gets({
                headers: {
                    skip: (this.page -1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            })
            this.ipItems = ipItems
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        create(){
            this.$router.push(`/dhbizadm/blacklist/create`);
        },

        edit(ip){
            this.$router.push(`/dhbizadm/blacklist/${ip._id}`);
        },

        async remove(ip){
            if(confirm("정말 삭제하시겠습니까?")){
                await api.console.ip.delete(ip);
                alert("삭제되었습니다");
                this.search();
            }
        }
    }
}
</script>