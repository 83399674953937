<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" md="4" lg="">
                <v-datetime-field v-model="query.createdAt[0]" label="검색일자" placeholder=" " hide-details clearable v-bind="attrs_input"></v-datetime-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-datetime-field v-model="query.createdAt[1]" label="검색일자" placeholder=" " hide-details clearable v-bind="attrs_input"></v-datetime-field>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="12" sm="6" md="4" lg="">
                <v-combobox v-model="query.type" :items="types" label="TYPE" placeholder=" " hide-details v-bind="attrs_input" @keydown.enter="page = 1;  search(true)" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-select v-model="query.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" v-bind="attrs_input" placeholder=" " hide-details @input="query.searchValue = null;" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="">
                <v-text-field v-model="query.searchValue" label="검색어" placeholder=" " hide-details v-bind="attrs_input" :disabled="!query.searchKey" @keydown.enter="page = 1; search(true)" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input, attrs_switch } from "@/assets/variables";
import dayjs from "dayjs";

import ListSearch from "@/components/console/dumb/list-search.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";


let initQuery = (query = {}) => ({
    ...query,
    ["createdAt"]: query?.["createdAt"] || [`${dayjs().format("YYYY-MM-DD")} 00:00:00`, `${dayjs().format("YYYY-MM-DD")} 23:59:59`],
    searchKey: query?.searchKey || null,
    searchValue: query?.searchValue || null,

    type: query?.type || null,
    isWarning: query?.isWarning || null,
});

export default {
    components: {
        ListSearch,
        VDatetimeField,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
        attrs_switch,
        types: [ "포인트 변경" ],

        searchKeys: [
            { text: ":: 전체 ::", value: null },
            { text: "연락처", value: "phone" },
        ],
    }),
    mounted() {
        this.sync();
        this.setQuery();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.searchKey) delete query.searchKey;
            if (!query?.searchValue) delete query.searchValue;

            if (!query?.type) delete query.type;
            if (!query?.isWarning) delete query.isWarning;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
