<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">상품문의</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAts[0]" label="등록일자" persistent-placeholder dense hide-details></v-date-field>
                                </v-col>

                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAts[1]" label="등록일자" persistent-placeholder dense hide-details></v-date-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-select v-model="filter.searchKey" :items="filterItems" label="상세조건" persistent-placeholder dense hide-details item-text="text" item-valuie="value"></v-select>
                                </v-col>
                                <v-col class="pt-0 pb-0" cols="12" sm="6" md="3">
                                    <v-text-field v-model="filter.searchValue" :disabled="filter.searchKey == null" dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="inquiresHeaders" :items="inquires" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:[`item.product`]="{ item }">
                        <v-row v-if="item.product" align="center">
                            <v-col md="auto">
                                <v-img v-if="item.product.thumb" :src="item.product.thumb?.url" max-width="56" max-height="56"></v-img>
                                <v-responsive v-else width="56" height="56">
                                    <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                        <v-icon>mdi-image</v-icon>
                                    </v-row>
                                </v-responsive>
                            </v-col>
                            <v-col>
                                <div class="text-start">{{ item.product.name }}</div>
                            </v-col>
                        </v-row>
                        <v-row v-else align="center">
                            <v-col md="auto">
                                <v-responsive width="56" height="56">
                                    <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                        <v-icon>mdi-image</v-icon>
                                    </v-row>
                                </v-responsive>
                            </v-col>
                            <v-col>
                                <div class="text-start">삭제된 상품</div>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:[`item.user`]="{ item }">
                        <v-row v-if="item.user">
                            <v-col>{{ item.user.name }}({{ item.user.username }})</v-col>
                        </v-row>
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>

                    <template v-slot:[`item.reply`]="{ item }">
                        <span v-if="item.reply">답변완료</span>
                        <span v-else>미답변</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    </template>

                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>


                <v-dialog v-model="inquireDialog" max-width="960px">
                    <v-card v-if="inquireDialog">
                        <v-card-title>
                            <span class="subtitle-1">상품문의</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                            <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>

                            <div class="caption mt-4">문의내용</div>
                            <div class="body-2" v-html="editItem.content"></div>
                            <v-row v-if="editItem.images && editItem.images.filter(image => image)">
                                <v-col v-for="(image,i) in editItem.images" :key="`image-${i}`" cols="auto">
                                    <v-dialog max-width="100%" width="unset">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="`/res/shop/inquires/${editItem._id}/${image}`" max-width="96" max-height="96" contain v-on="on" v-bind="attrs"></v-img>
                                        </template>

                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-img :src="`/res/shop/inquires/${editItem._id}/${image}`" contain>
                                                    <v-system-bar lights-out dark class="pa-0">
                                                        <v-spacer></v-spacer>
                                                        <v-icon @click="dialog.value = false">mdi-close</v-icon>
                                                    </v-system-bar>
                                                </v-img>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <div class="caption mt-4">답변</div>
                            <naver-smarteditor v-model="editItem.reply"></naver-smarteditor>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        VDateField,
        NaverSmarteditor
    },
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                createdAts: ['', ''],
                searchKey: '',
                searchValue: ''
            },

            filterItems: [
                { text: "전체", value: '' },
                { text: "상품명", value: "product.name" },
                { text: "회원아이디", value: "user.username" },
            ],

            ///////////////////////////////////////////////
            // 상품문의 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            inquires: [],
            inquiresHeaders: [{
                text: '상품',
                align: 'center',
                value: 'product'
            }, {
                text: '작성자',
                align: 'center',
                value: 'user'
            }, {
                text: '문의내용',
                align: 'center',
                value: 'subject'
            }, {
                text: '작성일자',
                align: 'center',
                width: 170,
                value: 'createdAt'
            }, {
                text: '답변상태',
                align: 'center',
                width: 170,
                value: 'reply'
            }, {
                text: 'Actions',
                align: 'center',
                value: 'actions'
            }],

            ///////////////////////////////////////////////////
            //
            ///////////////////////////////////////////////////
            inquireDialog: false,
            editItem: {}
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search(){
            try{
                var { summary, inquires } = await api.console.shop.inquires.getInquires({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.inquires = inquires;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        edit(inquire){
            this.editItem = Object.assign({}, inquire);
            this.inquireDialog = true;
        },

        close(){
            this.inquireDialog = false;
        },

        async save(){
            try{
                await api.console.shop.inquires.putInquire(this.editItem);
                await this.search();
                alert("저장되었습니다");
                this.inquireDialog = false;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>
