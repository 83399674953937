var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "검색엔진최적화(SEO) (사용/미사용)",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "value": true
          },
          model: {
            value: _vm.seoEnabled,
            callback: function ($$v) {
              _vm.seoEnabled = $$v;
            },
            expression: "seoEnabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.seoEnabled,
      expression: "seoEnabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.seo, "title", $$v);
      },
      expression: "seo.title"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.author.content,
      callback: function ($$v) {
        _vm.$set(_vm.author, "content", $$v);
      },
      expression: "author.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.decription.content,
      callback: function ($$v) {
        _vm.$set(_vm.decription, "content", $$v);
      },
      expression: "decription.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.keywords.content,
      callback: function ($$v) {
        _vm.$set(_vm.keywords, "content", $$v);
      },
      expression: "keywords.content"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }