<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="탈퇴회원 목록" v-model="showsSearch" showsFilterButton />
            <user-list-search v-bind="{ showsSearch }" />
            <v-row>
                <v-spacer></v-spacer>
                <!-- <v-col cols="auto">
                    <v-btn color="white green--text" class=" px-2" @click="excel">
                        <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-data-table :headers="usersHeaders" :items="users" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined mt-4">
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? item.createdAt.toDateTime() : null }}
                    </template>
                    <template v-slot:item.phone="{ item }">
                        {{ item.phone ? item.phone.phoneNumberFormat() : null }}
                    </template>
                    <template v-slot:item.level="{ item }">
                        {{ item.level ? item.level.name : '-' }}
                    </template>
                    <template v-slot:item.isWarning="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.isWarning" color="red lighten-1" hide-details dens class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
        <edit-dialog-user-point ref="userPoint" @init="search"/>
    </v-layout>
</template>

<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
export default {
    components: {
        ListHeading,
        UserListSearch
    },
    data() {
        return {
            showsSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,
            filter: {
                name: this.$route.query.name,
                phone: this.$route.query.phone,
                _level: this.$route.query._level,
                isWarning: undefined,
                isWithdrawn: true
            },

            users: [],
            usersHeaders: [
                { text: '사용자 이름', align: 'center', width: 200, divider: true, value: 'name' },
                { text: '연락처', align: 'center', width: 128, divider: true, value: 'phone' },
                { text: '이메일', align: 'center', width: 128, divider: true, value: 'email' },
                { text: '등급', align: 'center', width: 140, divider: true, value: 'level' },
                { text: '주의회원', width: 100, align: 'center', divider: true, value: 'isWarning' },
                { text: "탈퇴사유", align: "center", divider: true, value: "withdrawalReason" },
                { text: "남기신말", align: "center", divider: true, value: "withdrawalMessage" },
                { text: '가입일자', align: 'center', divider: true, width: 160, value: 'createdAt' },
                { text: "", align: "center", value: "actions", width: +60,  },
            ],

            isWarningItems: [
                { text: "전체", value: undefined },
                { text: "주의회원", value: true },
                { text: "미주의회원", value: false }
            ]

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.users = users;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        edit(user){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _user: user._id
                }
            });
        },
        async remove(user) {
            if (confirm("회원을 삭제하시겠습니까?")) {
                await api.console.users.delete({ _id: user._id })
                alert("회원이 삭제되었습니다")
                await this.init();
            }
        }
    }
}
</script>
