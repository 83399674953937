<template>
    <v-dialog v-model="show" max-width="400">
        <v-card v-if="show">
            <v-card-title class="subtitle-1 font-weight-bold">포인트 충전</v-card-title>
            <v-card-text>
                <v-text-field v-model="cash.price" label="금액" type="number" placeholder=" " :hint="(+cash.price || 0).format()" persistent-hint></v-text-field>
                <v-text-field v-model="cash.remark" label="비고" placeholder=" " hide-details class="mt-3"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="grey" @click="close">취소</v-btn>
                <v-btn text color="primary" @click="save" :disabled="disabled">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
export default {
	data() {
		return {
			show: false,
			user: null,
			cash: {
				price: 0,
				remark: null
			},
		}
	},
	methods: {
		open(user) {
			this.user = user;
			this.show = true;
		},
		close() {
			this.clear()
			this.user = null
			this.show = false;
		},
		clear() {
			this.cash = {
				price: 0,
				remark: null
			}
			this.disabled = false;
		},
		async save() {
			if (confirm(`[(회원명 - ${this.user.name})에 (${Number(+this.cash.price).format()})포인트를 충전하시겠습니까?]`)) {
				this.disabled = true;
				await api.console.users.points.postPoint({
					_user: this.user._id,
					amount: this.cash.price,
					remark: this.cash.remark,
					type: "CHARGE"
				})
				alert(`${this.user.name}님에게 ${Number(+this.cash.price).format()}포인트가 충전되었습니다`)
				this.$emit("init")
				this.close();
			}
		}
	}
}
</script>

<style>

</style>