<template>
    <v-row class="pa-0">
        <v-col v-for="depth in count" :key="depth" cols="12" :md="12/count" :sm="12/count" class="py-3 py-sm-0">
            <v-select :items="items(depth)" item-text="name" item-value="_id" :value="select(depth)" @input="input" :label="`${depth}차 카테고리`" persistent-placeholder :dense="dense" hide-details></v-select>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        value: { type: Object, default: null },
        categories: { type: Array, default: [] }
    },
    data: () => {
        return {
            category: null
        };
    },
    created(){
        this.category = this.value;
    },
    methods: {
        input(_category){
            var category = this.findCategory(_category, this.categories);
            this.category = category;
            this.$emit('input', category);
        },
        findCategory(_category, categories = null){
            if(!categories) categories = this.categories;

            for(var i in categories){
                if(categories[i]._id == _category) return categories[i];
                else if(categories[i].children){
                    var result = this.findCategory(_category, categories[i].children);
                    if(result) return result;
                }
            }

            return null;
        },
        items(depth, categories = null){
            if(!categories) categories = this.categories;
            if(!this.category && depth == 1) return categories;

            if(this.category){
                for(var i in categories){
                    if(categories[i]._id == this.category._id){
                        if(depth <= this.category.depth) return categories;
                        else if(this.category.depth + 1 == depth) return categories[i].children;
                    }
                    else if(categories[i].children){
                        var result = this.items(depth, categories[i].children);
                        if(result.length) return depth <= categories[i].depth ? categories : result;
                    }
                }
            }

            return [];
        },
        select(depth, categories = null){
            if(this.category) return [].concat(this.category._parents, this.category._id)[depth - 1];
            return null;
        },
        findDepth(categories = null, depth = 1){
            var maxDepth = depth;
            if(!categories) categories = this.categories;
            for(var i in categories){
                if(categories[i].children && categories[i].children.length){
                    var result = this.findDepth(categories[i].children, depth + 1);
                    if(maxDepth < result) maxDepth = result;
                }
            }
            return maxDepth;
        },
    },
    computed: {
        count(){
            return this.findDepth();
        }
    },
    watch: {
        value(){
            this.category = this.value;
        }
    }
}
</script>
