var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "탈퇴회원 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('user-list-search', _vm._b({}, 'user-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', [_c('v-spacer')], 1), _c('v-data-table', {
    staticClass: "v-sheet--outlined mt-4",
    attrs: {
      "headers": _vm.usersHeaders,
      "items": _vm.users,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.createdAt",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.createdAt ? item.createdAt.toDateTime() : null) + " ")];
      }
    }, {
      key: "item.phone",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.phone ? item.phone.phoneNumberFormat() : null) + " ")];
      }
    }, {
      key: "item.level",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.level ? item.level.name : '-') + " ")];
      }
    }, {
      key: "item.isWarning",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "mt-0",
          attrs: {
            "color": "red lighten-1",
            "hide-details": "",
            "dens": ""
          },
          model: {
            value: item.isWarning,
            callback: function ($$v) {
              _vm.$set(item, "isWarning", $$v);
            },
            expression: "item.isWarning"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1), _c('edit-dialog-user-point', {
    ref: "userPoint",
    on: {
      "init": _vm.search
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }