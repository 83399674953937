var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("상품목록")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsSearch = !_vm.showsSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsSearch,
      expression: "showsSearch"
    }],
    staticClass: "elevation-1 mx-auto"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("검색필터")]), _c('v-card-text', {
    staticClass: "pt-4 pb-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-category-select', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.filter._category = _vm.category._id;
      }
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "상품명",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.name,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.newlyItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "신상여부",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.newly,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "newly", $$v);
      },
      expression: "filter.newly"
    }
  })], 1), _c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.bestItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "베스트",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.best,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "best", $$v);
      },
      expression: "filter.best"
    }
  })], 1), _c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.specialItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "특가여부",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.special,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "special", $$v);
      },
      expression: "filter.special"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.displayItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "진열여부",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.display,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "display", $$v);
      },
      expression: "filter.display"
    }
  })], 1), _c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.soldoutItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "품절상태여부",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.soldout,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "soldout", $$v);
      },
      expression: "filter.soldout"
    }
  })], 1), _c('v-col', {
    staticClass: "py-sm-0",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.saleItems,
      "item-text": "text",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "type": "text",
      "label": "판매여부",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.sale,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "sale", $$v);
      },
      expression: "filter.sale"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.page = 1;
        _vm.search();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.productsHeaders,
      "items": _vm.products,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.goods",
      fn: function (_ref) {
        var _item$thumb;
        var item = _ref.item;
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.name))])])], 1)];
      }
    }, {
      key: "item.price",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.price.format()) + "원")])];
      }
    }, {
      key: "item.cost",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.cost.format()) + "원")])];
      }
    }, {
      key: "item.stock",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.stock.format()) + "개")])];
      }
    }, {
      key: "item.display",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                display: item.display
              });
            }
          },
          model: {
            value: item.display,
            callback: function ($$v) {
              _vm.$set(item, "display", $$v);
            },
            expression: "item.display"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.sale",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                sale: item.sale
              });
            }
          },
          model: {
            value: item.sale,
            callback: function ($$v) {
              _vm.$set(item, "sale", $$v);
            },
            expression: "item.sale"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.best",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                best: item.best
              });
            }
          },
          model: {
            value: item.best,
            callback: function ($$v) {
              _vm.$set(item, "best", $$v);
            },
            expression: "item.best"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.newly",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                newly: item.newly
              });
            }
          },
          model: {
            value: item.newly,
            callback: function ($$v) {
              _vm.$set(item, "newly", $$v);
            },
            expression: "item.newly"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.special",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                special: item.special
              });
            }
          },
          model: {
            value: item.special,
            callback: function ($$v) {
              _vm.$set(item, "special", $$v);
            },
            expression: "item.special"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.soldout",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                soldout: item.soldout
              });
            }
          },
          model: {
            value: item.soldout,
            callback: function ($$v) {
              _vm.$set(item, "soldout", $$v);
            },
            expression: "item.soldout"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }