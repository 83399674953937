<template>
    <v-dialog v-model="shows" width="720" :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card tile>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.name" label="색상명" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-text-field v-model="form.hexa" label="HEXA" v-bind="attrs_input">
                            <template #append>
                                <v-card flat outlined width="24" height="24" rounded="xl" :color="form.hexa" class="ma-0"> </v-card>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-card outlined class="fill-height">
                            <v-card-text style="position: relative">
                                <span class="d-inline-block white caption pa-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%)">파일</span>
                                <image-input v-model="form.img" :aspect-ratio="1 / 1" width="100%" flat />
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-card outlined class="fill-height">
                            <v-card-text style="position: relative">
                                <span class="d-inline-block white caption pa-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%)">색상</span>
                                <v-card flat style="overflow: hidden">
                                    <v-responsive :aspect-ratio="1 / 1" height="294" style="overflow-y: auto">
                                        <v-color-picker v-model="form.hexa" @update:color="changeColor" hide-canvas hide-sliders hide-inputs show-swatches flat width="100%" swatches-max-height="100%" />
                                    </v-responsive>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>

        <v-fade-transition>
            <v-overlay v-show="loading" absolute light color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>
    </v-dialog>
</template>

<script>
import { attrs_input, initProductOptionCriterionValue } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";

export default {
    components: {
        ImageInput,
    },
    props: {
        value: { type: Object, default: initProductOptionCriterionValue },
    },
    data: () => ({
        form: initProductOptionCriterionValue(),

        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "항목 상세" : "항목 생성";
        },
        src() {
            if (this.form.img instanceof File) return URL.createObjectURL(this.form.img);
            else return this.form.img?.url;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initProductOptionCriterionValue(this.isCreate ? undefined : this.value);
        },
        save() {
            this.$emit("input", this.form);
            this.shows = false;
        },
        changeColor({ hexa } = {}) {
            this.form.hexa = hexa;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-color-picker__swatches > div {
        padding: 0;
    }

    .v-color-picker__swatch {
        margin: 0;
    }
    .v-color-picker__color {
        margin: 2px;
        width: 22px;
        height: 22px;
        max-height: 22px;
        border-radius: 50%;
    }
}
</style>
