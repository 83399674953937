<template>
	<v-layout justify-center>
		<v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="로그" v-model="showsSearch" showsFilterButton />
            <log-list-search v-bind="{ showsSearch }"/>
			
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white green--text" class=" px-2" @click="excel">
                        <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table :items="logs" :headers="logsHeaders" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.name`]="{ item }">
                    {{ item.name || workers.find(worker => worker.phone == item.phone)?.name || "-" }}
                </template>
                <template #[`item.memo`]="{ item }">
                    <v-text-field v-model="item.memo" outlined hide-details dense clearable append-icon="save" @click:append="save(item)" />
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
		</v-responsive>
	</v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import LogListSearch from "@/components/console/logs/log-list-search.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ListHeading,
        LogListSearch,
        VDatetimeField
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showsSearch: true,

            // filter: {
            //     createdAt: [this.$route.query.createdAt?.[0] || `${this.$dayjs().format("YYYY-MM-DD")} 00:00:00`, this.$route.query.createdAt?.[1] || `${this.$dayjs().format("YYYY-MM-DD")} 23:59:59`],
            //     type: this.$route.query.type || null,
            //     searchKey: this.$route.query.searchKey || null,
            //     searchValue: this.$route.query.searchValue || null
            // },
            limit: 10,

            summary: { totalCount: 0 },
            logs: [],

            logsHeaders: [
                { text: "type", value: "type", divider: true, width: 100 },
                { text: "이름", value: "name", divider: true, width: 160 },
                { text: "연락처", value: "phone", divider: true, width: 160 },
                { text: "비고", value: "remark", divider: true, width: 160 },
                { text: "메모", value: "memo", divider: true, width: 160 },
                { text: "날짜", value: "createdAt", width: 100 },
            ],

            workers: [
                { name: "서순명", phone: "01048773837" },
                { name: "남지영", phone: "01056078657" },
                { name: "조용준", phone: "01051921901" },
                { name: "전채연", phone: "01056240212" },
                { name: "박현수", phone: "01094989473" },
                { name: "우상덕", phone: "01046004701" },
                { name: "박기란", phone: "01056289901" },
                { name: "채선영", phone: "01064797533" },
                { name: "유기찬", phone: "01030990119" },
                { name: "김기준", phone: "01086085724" },
                { name: "이혜정", phone: "01033820100" },
                { name: "송지영", phone: "01089014068" },
                { name: "김철호", phone: "01024602584" },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            const { skip, limit, params } = this;
            let { summary, logs } = await api.console.logs.gets({ headers: { skip, limit }, params });

            this.logs = logs;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async save(log){
            if(confirm("메모를 저장하시겠습니까?")){
                await api.console.logs.put(log);
            }
        },
        async excel(){
            let { logs } = await api.console.logs.gets({ params });

            let rows = [];
            logs.forEach(log => {
                rows.push({
                    "type": log.type,
                    "이름": this.workers.find(worker => worker.phone == log.phone)?.name || "-",
                    "연락처": log.phone,
                    "비고": log.remark,
                    "날짜": this.$dayjs(log.createdAt).format("YYYY-MM-DD HH:mm:ss")
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "로그");
            XLSX.writeFile(workbook, "로그.xlsx");
        },
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    watch: {
        params() {
            this.init()
        },
    },
}
</script>