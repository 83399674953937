<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" class="shop-coupon-view">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                    <span v-if="$route.params._coupon === undefined">쿠폰 등록</span>
                    <span v-else>쿠폰 정보</span>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>

            <v-tabs v-model="tab" background-color="content" class="mt-4">
                <v-tab key="first" :disabled="!!$route.params._coupon">첫구매고객</v-tab>
                <v-tab key="second" :disabled="!!$route.params._coupon">재구매고객</v-tab>
                <v-tab key="target" :disabled="!!$route.params._coupon">타겟팅</v-tab>
                <v-tab-item key="first">
                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold"><v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>첫구매고객 대상</v-card-title>
                        <v-card-text>
                            <span class="caption d-block">최근 2년간 구매이력이 없는 고객을 대상으로 혜택을 설정할 수 있습니다.</span>
                            <span class="caption d-block">첫구매고객을 위한 첫구매 쿠폰(다운로드) 혜택을 등록해 보세요.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰명칭</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="firstCoupon.name" label="쿠폰 이름" persistent-placeholder dense hide-details></v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰종류</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="firstCoupon.type" dense hide-details row class="mt-0">
                                <v-radio label="상품할인" value="product"></v-radio>
                                <v-radio label="카테고리할인" value="category"></v-radio>
                                <v-radio label="주문금액할인" value="order"></v-radio>
                                <v-radio label="배송비할인" value="delivery"></v-radio>
                            </v-radio-group>

                            <shop-category-select v-if="firstCoupon.type == 'category'" v-model="firstCoupon.category" :categories="categories" @input="firstCoupon._category = firstCoupon.category._id" class="mt-4"></shop-category-select>

                            <v-autocomplete v-if="firstCoupon.type == 'product'" v-model="firstCoupon._product" :loading="productLoading" :search-input.sync="searchValue" label="할인상품" persistent-placeholder :items="products" item-text="name" item-value="_id" hide-details class="mt-4">
                                <template v-slot:selection="{ attr, on, item, selected }">
                                    <v-card class="mr-4 px-2 my-2">
                                        <v-row align="center">
                                            <v-col cols="auto">
                                                <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                            </v-col>
                                            <v-col class="pl-0">{{ item.name }}</v-col>
                                        </v-row>
                                    </v-card>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-1">
                                            <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                        </v-col>
                                        <v-col class="py-1 pl-0">{{ item.name }}</v-col>
                                    </v-row>
                                </template>
                            </v-autocomplete>

                            <span v-if="firstCoupon.type == 'product'" class="caption primary--text d-block mt-2">즉시할인과 중복으로 사용할 수 있는쿠폰입니다.</span>
                            <span v-if="firstCoupon.type == 'order'" class="caption primary--text d-block mt-2">쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)</span>
                            <span v-if="firstCoupon.type == 'delivery'" class="caption primary--text d-block mt-2">배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.</span>

                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">발급방법</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="firstCoupon.method" dense hide-details row class="mt-0">
                                <v-radio label="다운로드" value="download"></v-radio>
                                <v-radio label="고객에게 즉시발급" value="immediately"></v-radio>
                            </v-radio-group>

                            <span class="caption primary--text d-block mt-2">고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">할인설정</v-card-title>
                        <v-card-text>
                            <v-row align="center" class="m">
                                <v-col cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="8" class="py-0">
                                            <v-text-field v-model="firstCoupon.amount" label="할인금액" persistent-placeholder dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0">
                                            <v-select v-model="firstCoupon.unit" :items="amountUnits" label="단위" persistent-placeholder dense hide-details></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="firstCoupon.unit == '%'" cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">최대</v-col>
                                        <v-col class="py-0">
                                            <v-text-field v-model="firstCoupon.maxPrice" dense hide-details></v-text-field>
                                        </v-col class="py-0">
                                        <v-col cols="auto" class="py-0">원 할인</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">최소주문금액</v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col class="py-0">
                                            <v-text-field v-model="firstCoupon.minPrice" dense hide-details ></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="py-0">원 이상 구매시 사용 가능</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block mt-2">· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다</span>
                            <span class="caption primary--text d-block">( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )</span>
                        </v-card-text>
                    </v-card>

                    <v-card v-if="firstCoupon.method == 'download'" class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col class="py-0">혜택기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pr-0 caption">특정 기간만 혜택 제공</v-col>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="firstCoupon.download.period" dense hide-details class="ma-0 pa-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="firstCoupon.download.period">
                            <v-radio-group :value="getTerm(firstCoupon.download.startDate, firstCoupon.download.endDate)" @change="setTerm($event, firstCoupon.download)" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="1주일" :value="0.25"></v-radio>
                                <v-radio label="1개월" :value="1"></v-radio>
                                <v-radio label="3개월" :value="3"></v-radio>
                                <v-radio label="6개월" :value="6"></v-radio>
                                <v-radio label="1년" :value="12"></v-radio>
                            </v-radio-group>

                            <v-row class="mt-4">
                                <v-col>
                                    <v-menu ref="firstDownloadStartDate" :close-on-content-click="false" :return-value.sync="firstCoupon.download.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="firstCoupon.download.startDate" label="혜택시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="firstCoupon.download.startDate" no-title scrollable @input="$refs.firstDownloadStartDate.save(firstCoupon.download.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu ref="firstDownloadEndDate" :close-on-content-click="false" :return-value.sync="firstCoupon.download.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="firstCoupon.download.endDate" label="혜택종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="firstCoupon.download.endDate" no-title scrollable @input="$refs.firstDownloadEndDate.save(firstCoupon.download.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block"> 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">쿠폰유효기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="firstCoupon.expiration.enabled" dense hide-details class="pa-0 ma-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="firstCoupon.expiration.enabled">
                            <v-radio-group v-model="firstCoupon.expiration.type" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="기간으로설정" value="dates"></v-radio>
                                <v-radio label="발급일 기준으로 설정" value="days"></v-radio>
                            </v-radio-group>

                            <v-row v-if="firstCoupon.expiration.type == 'dates'" class="mt-6">
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationStartDate" :close-on-content-click="false" :return-value.sync="firstCoupon.expiration.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="firstCoupon.expiration.startDate" label="시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="firstCoupon.expiration.startDate" no-title scrollable @input="$refs.firstExpirationStartDate.save(firstCoupon.expiration.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationEndDate" :close-on-content-click="false" :return-value.sync="firstCoupon.expiration.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="firstCoupon.expiration.endDate" label="종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="firstCoupon.expiration.endDate" no-title scrollable @input="$refs.firstExpirationEndDate.save(firstCoupon.expiration.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <div v-if="firstCoupon.expiration.type == 'dates'" class="caption primary--text mt-3">특정 기간동안 쿠폰 유효</div>

                            <v-row v-if="firstCoupon.expiration.type == 'days'" align="center" class="mt-6">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">발급일로부터</v-col>
                                        <v-col class="py-0"><v-text-field v-model="firstCoupon.expiration.days" dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0">일 간 유효</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="second">
                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold"><v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>재구매고객 대상</v-card-title>
                        <v-card-text>
                            <span class="caption d-block">최근 6개월간 구매이력이 있는 고객을 대상으로 재구매 혜택을 설정할 수 있습니다.</span>
                            <span class="caption d-block">재구매고객을 위해 쿠폰을 즉시 발급하거나, 구매 시 포인트 적립 혜택을 등록해 보세요.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰명칭</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="secondCoupon.name" label="쿠폰 이름" persistent-placeholder dense hide-details></v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰종류</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="secondCoupon.type" dense hide-details row class="mt-0">
                                <v-radio label="상품할인" value="product"></v-radio>
                                <v-radio label="카테고리할인" value="category"></v-radio>
                                <v-radio label="주문금액할인" value="order"></v-radio>
                                <v-radio label="배송비할인" value="delivery"></v-radio>
                            </v-radio-group>

                            <shop-category-select v-if="secondCoupon.type == 'category'" v-model="secondCoupon.category" :categories="categories" @input="secondCoupon._category = secondCoupon.category._id" class="mt-4"></shop-category-select>

                            <v-autocomplete v-if="secondCoupon.type == 'product'" v-model="secondCoupon._product" :loading="productLoading" :search-input.sync="searchValue" label="할인상품" persistent-placeholder :items="products" item-text="name" item-value="_id" hide-details class="mt-4">
                                <template v-slot:selection="{ attr, on, item, selected }">
                                    <v-card class="mr-4 px-2 my-2">
                                        <v-row align="center">
                                            <v-col cols="auto">
                                                <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                            </v-col>
                                            <v-col class="pl-0">{{ item.name }}</v-col>
                                        </v-row>
                                    </v-card>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-1">
                                            <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                        </v-col>
                                        <v-col class="py-1 pl-0">{{ item.name }}</v-col>
                                    </v-row>
                                </template>
                            </v-autocomplete>

                            <span v-if="secondCoupon.type == 'product'" class="caption primary--text d-block mt-2">즉시할인과 중복으로 사용할 수 있는쿠폰입니다.</span>
                            <span v-if="secondCoupon.type == 'order'" class="caption primary--text d-block mt-2">쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)</span>
                            <span v-if="secondCoupon.type == 'delivery'" class="caption primary--text d-block mt-2">배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.</span>

                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">발급방법</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="secondCoupon.method" dense hide-details row class="mt-0">
                                <v-radio label="다운로드" value="download"></v-radio>
                                <v-radio label="고객에게 즉시발급" value="immediately"></v-radio>
                            </v-radio-group>

                            <span class="caption primary--text d-block mt-2">고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">할인설정</v-card-title>
                        <v-card-text>
                            <v-row align="center" class="m">
                                <v-col cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="8" class="py-0">
                                            <v-text-field v-model="secondCoupon.amount" label="할인금액" persistent-placeholder dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0">
                                            <v-select v-model="secondCoupon.unit" :items="amountUnits" label="단위" persistent-placeholder dense hide-details></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="secondCoupon.unit == '%'" cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">최대</v-col>
                                        <v-col class="py-0">
                                            <v-text-field v-model="secondCoupon.maxPrice" dense hide-details></v-text-field>
                                        </v-col class="py-0">
                                        <v-col cols="auto" class="py-0">원 할인</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">최소주문금액</v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col class="py-0">
                                            <v-text-field v-model="secondCoupon.minPrice" dense hide-details ></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="py-0">원 이상 구매시 사용 가능</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block mt-2">· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다</span>
                            <span class="caption primary--text d-block">( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )</span>
                        </v-card-text>
                    </v-card>

                    <v-card v-if="secondCoupon.method == 'download'" class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col class="py-0">혜택기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pr-0 caption">특정 기간만 혜택 제공</v-col>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="secondCoupon.download.period" dense hide-details class="ma-0 pa-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="secondCoupon.download.period">
                            <v-radio-group :value="getTerm(secondCoupon.download.startDate, secondCoupon.download.endDate)" @change="setTerm($event, secondCoupon.download)" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="1주일" :value="0.25"></v-radio>
                                <v-radio label="1개월" :value="1"></v-radio>
                                <v-radio label="3개월" :value="3"></v-radio>
                                <v-radio label="6개월" :value="6"></v-radio>
                                <v-radio label="1년" :value="12"></v-radio>
                            </v-radio-group>

                            <v-row class="mt-4">
                                <v-col>
                                    <v-menu ref="firstDownloadStartDate" :close-on-content-click="false" :return-value.sync="secondCoupon.download.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="secondCoupon.download.startDate" label="혜택시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="secondCoupon.download.startDate" no-title scrollable @input="$refs.firstDownloadStartDate.save(secondCoupon.download.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu ref="firstDownloadEndDate" :close-on-content-click="false" :return-value.sync="secondCoupon.download.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="secondCoupon.download.endDate" label="혜택종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="secondCoupon.download.endDate" no-title scrollable @input="$refs.firstDownloadEndDate.save(secondCoupon.download.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block"> 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">쿠폰유효기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="secondCoupon.expiration.enabled" dense hide-details class="pa-0 ma-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="secondCoupon.expiration.enabled">
                            <v-radio-group v-model="secondCoupon.expiration.type" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="기간으로설정" value="dates"></v-radio>
                                <v-radio label="발급일 기준으로 설정" value="days"></v-radio>
                            </v-radio-group>

                            <v-row v-if="secondCoupon.expiration.type == 'dates'" class="mt-6">
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationStartDate" :close-on-content-click="false" :return-value.sync="secondCoupon.expiration.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="secondCoupon.expiration.startDate" label="시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="secondCoupon.expiration.startDate" no-title scrollable @input="$refs.firstExpirationStartDate.save(secondCoupon.expiration.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationEndDate" :close-on-content-click="false" :return-value.sync="secondCoupon.expiration.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="secondCoupon.expiration.endDate" label="종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="secondCoupon.expiration.endDate" no-title scrollable @input="$refs.firstExpirationEndDate.save(secondCoupon.expiration.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <div v-if="secondCoupon.expiration.type == 'dates'" class="caption primary--text mt-3">특정 기간동안 쿠폰 유효</div>

                            <v-row v-if="secondCoupon.expiration.type == 'days'" align="center" class="mt-6">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">발급일로부터</v-col>
                                        <v-col class="py-0"><v-text-field v-model="secondCoupon.expiration.days" dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0">일 간 유효</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-tab-item>


                <v-tab-item key="target" >
                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold"><v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>타겟팅 대상</v-card-title>
                        <v-card-text>
                            <span class="caption d-block">판매자님이 원하는 고객에게 쿠폰을 등록할 수 있습니다.</span>

                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="12" md="8" class="py-0">
                                    <v-row align="center">
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="auto" class="caption pb-0 pb-sm-3">회원이름</v-col>
                                        <v-col class="px-sm-0 py-0"><v-text-field v-model="userFilter.searchValue" dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0"><v-btn color="secondary" small @click="searchUsers">검색</v-btn></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-data-table v-model="usersSelected" :items="users" :headers="usersHeaders" show-select hide-default-footer></v-data-table>

                            <v-pagination v-model="userPage" :length="userPageCount" :total-visible="10" color="secondary" class="mt-2" @input="searchUsers"></v-pagination>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰명칭</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="targetCoupon.name" label="쿠폰 이름" persistent-placeholder dense hide-details></v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">쿠폰종류</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="targetCoupon.type" dense hide-details row class="mt-0">
                                <v-radio label="상품할인" value="product"></v-radio>
                                <v-radio label="카테고리할인" value="category"></v-radio>
                                <v-radio label="주문금액할인" value="order"></v-radio>
                                <v-radio label="배송비할인" value="delivery"></v-radio>
                            </v-radio-group>

                            <shop-category-select v-if="targetCoupon.type == 'category'" v-model="targetCoupon.category" :categories="categories" @input="targetCoupon._category = targetCoupon.category._id" class="mt-4"></shop-category-select>

                            <v-autocomplete v-if="targetCoupon.type == 'product'" v-model="targetCoupon._product" :loading="productLoading" :search-input.sync="searchValue" label="할인상품" persistent-placeholder :items="products" item-text="name" item-value="_id" hide-details class="mt-4">
                                <template v-slot:selection="{ attr, on, item, selected }">
                                    <v-card class="mr-4 px-2 my-2">
                                        <v-row align="center">
                                            <v-col cols="auto">
                                                <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                            </v-col>
                                            <v-col class="pl-0">{{ item.name }}</v-col>
                                        </v-row>
                                    </v-card>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-1">
                                            <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"></v-img>
                                        </v-col>
                                        <v-col class="py-1 pl-0">{{ item.name }}</v-col>
                                    </v-row>
                                </template>
                            </v-autocomplete>

                            <span v-if="targetCoupon.type == 'product'" class="caption primary--text d-block mt-2">즉시할인과 중복으로 사용할 수 있는쿠폰입니다.</span>
                            <span v-if="targetCoupon.type == 'order'" class="caption primary--text d-block mt-2">쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)</span>
                            <span v-if="targetCoupon.type == 'delivery'" class="caption primary--text d-block mt-2">배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.</span>

                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">발급방법</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="targetCoupon.method" dense hide-details row class="mt-0">
                                <v-radio label="다운로드" value="download"></v-radio>
                                <v-radio label="고객에게 즉시발급" value="immediately"></v-radio>
                            </v-radio-group>

                            <span class="caption primary--text d-block mt-2">고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">할인설정</v-card-title>
                        <v-card-text>
                            <v-row align="center" class="m">
                                <v-col cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="8" class="py-0">
                                            <v-text-field v-model="targetCoupon.amount" label="할인금액" persistent-placeholder dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-0">
                                            <v-select v-model="targetCoupon.unit" :items="amountUnits" label="단위" persistent-placeholder dense hide-details></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="targetCoupon.unit == '%'" cols="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">최대</v-col>
                                        <v-col class="py-0">
                                            <v-text-field v-model="targetCoupon.maxPrice" dense hide-details></v-text-field>
                                        </v-col class="py-0">
                                        <v-col cols="auto" class="py-0">원 할인</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">최소주문금액</v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col class="py-0">
                                            <v-text-field v-model="targetCoupon.minPrice" dense hide-details ></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="py-0">원 이상 구매시 사용 가능</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block mt-2">· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다</span>
                            <span class="caption primary--text d-block">( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )</span>
                        </v-card-text>
                    </v-card>

                    <v-card v-if="targetCoupon.method == 'download'" class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col class="py-0">혜택기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pr-0 caption">특정 기간만 혜택 제공</v-col>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="targetCoupon.download.period" dense hide-details class="ma-0 pa-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="targetCoupon.download.period">
                            <v-radio-group :value="getTerm(targetCoupon.download.startDate, targetCoupon.download.endDate)" @change="setTerm($event, targetCoupon.download)" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="1주일" :value="0.25"></v-radio>
                                <v-radio label="1개월" :value="1"></v-radio>
                                <v-radio label="3개월" :value="3"></v-radio>
                                <v-radio label="6개월" :value="6"></v-radio>
                                <v-radio label="1년" :value="12"></v-radio>
                            </v-radio-group>

                            <v-row class="mt-4">
                                <v-col>
                                    <v-menu ref="firstDownloadStartDate" :close-on-content-click="false" :return-value.sync="targetCoupon.download.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="targetCoupon.download.startDate" label="혜택시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="targetCoupon.download.startDate" no-title scrollable @input="$refs.firstDownloadStartDate.save(targetCoupon.download.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu ref="firstDownloadEndDate" :close-on-content-click="false" :return-value.sync="targetCoupon.download.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="targetCoupon.download.endDate" label="혜택종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="targetCoupon.download.endDate" no-title scrollable @input="$refs.firstDownloadEndDate.save(targetCoupon.download.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <span class="caption primary--text d-block"> 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.</span>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-4" :disabled="!!$route.params._coupon">
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">쿠폰유효기간</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <v-switch v-model="targetCoupon.expiration.enabled" dense hide-details class="pa-0 ma-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="targetCoupon.expiration.enabled">
                            <v-radio-group v-model="targetCoupon.expiration.type" row dense hide-details class="ma-0 pa-0">
                                <v-radio label="기간으로설정" value="dates"></v-radio>
                                <v-radio label="발급일 기준으로 설정" value="days"></v-radio>
                            </v-radio-group>

                            <v-row v-if="targetCoupon.expiration.type == 'dates'" class="mt-6">
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationStartDate" :close-on-content-click="false" :return-value.sync="targetCoupon.expiration.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="targetCoupon.expiration.startDate" label="시작일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="targetCoupon.expiration.startDate" no-title scrollable @input="$refs.firstExpirationStartDate.save(targetCoupon.expiration.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-0">
                                    <v-menu ref="firstExpirationEndDate" :close-on-content-click="false" :return-value.sync="targetCoupon.expiration.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="targetCoupon.expiration.endDate" label="종료일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="targetCoupon.expiration.endDate" no-title scrollable @input="$refs.firstExpirationEndDate.save(targetCoupon.expiration.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <div v-if="targetCoupon.expiration.type == 'dates'" class="caption primary--text mt-3">특정 기간동안 쿠폰 유효</div>

                            <v-row v-if="targetCoupon.expiration.type == 'days'" align="center" class="mt-6">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-row align="center">
                                        <v-col cols="auto" class="py-0">발급일로부터</v-col>
                                        <v-col class="py-0"><v-text-field v-model="targetCoupon.expiration.days" dense hide-details></v-text-field></v-col>
                                        <v-col cols="auto" class="py-0">일 간 유효</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-tab-item>

            </v-tabs>

            <v-row justify="center" class="mt-4">
                <v-col cols="auto">
                    <v-btn color="primary" @click="save" :disabled="!!$route.params._coupon">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";

export default {
    components: {
        ShopCategorySelect,
    },
    data: () => {
        return {
            tab: 0,

            categories: [],

            productLoading: false,
            products: [],
            searchValue: null,

            firstCoupon: {
                name: null,
                kind: "first",
                type: "product",
                method: "download",
                amount: 0,
                unit: "원",
                maxPrice: 0,
                minPrice: 0,
                category: null,
                download: {
                    period: false,
                    startDate: null,
                    endDate: null,
                },
                expiration: {
                    enabled: false,
                    type: "dates",
                    startDate: null,
                    endDate: null,
                    days: 0,
                },
            },

            secondCoupon: {
                name: null,
                kind: "second",
                type: "product",
                method: "download",
                amount: 0,
                unit: "원",
                maxPrice: 0,
                minPrice: 0,
                category: null,
                download: {
                    period: false,
                    startDate: null,
                    endDate: null,
                },
                expiration: {
                    enabled: false,
                    type: "dates",
                    startDate: null,
                    endDate: null,
                    days: 0,
                },
            },

            targetCoupon: {
                name: null,
                kind: "target",
                type: "product",
                method: "download",
                amount: 0,
                unit: "원",
                maxPrice: 0,
                minPrice: 0,
                category: null,
                download: {
                    period: false,
                    startDate: null,
                    endDate: null,
                },
                expiration: {
                    enabled: false,
                    type: "dates",
                    startDate: null,
                    endDate: null,
                    days: 0,
                },
            },

            amountUnits: ["원", "%"],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            userPage: 1,
            userPageCount: 0,
            userLimit: 5,
            userFilter: {
                searchKey: "name",
                searchValue: null,
            },
            users: [],
            usersSelected: [],
            usersHeaders: [
                {
                    text: "사용자 이름",
                    align: "center",
                    width: 200,
                    value: "name",
                },
                {
                    text: "연락처",
                    align: "center",
                    width: 128,
                    value: "phone",
                },
                {
                    text: "이메일",
                    align: "center",
                    width: 128,
                    value: "email",
                },
            ],
        };
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            try {
                var { categories } = await api.console.shop.categories.getCategories();
                this.categories = categories;

                this.searchUsers();

                if (this.$route.params._coupon) {
                    var { coupon } = await api.console.shop.coupons.getCoupon({
                        _id: this.$route.params._coupon,
                    });

                    if (coupon.product) this.products.push(coupon.product);

                    switch (coupon.kind) {
                        case "first": {
                            this.firstCoupon = coupon;
                            this.tab = 0;
                            break;
                        }
                        case "second": {
                            this.secondCoupon = coupon;
                            this.tab = 1;
                            break;
                        }
                        case "target": {
                            this.targetCoupon = coupon;
                            this.users = coupon.users;
                            this.usersSelected = coupon.users;
                            this.tab = 2;
                            break;
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check(coupon) {
            try {
                console.log(coupon);
                if (!coupon.amount || coupon.amount < 0) throw new Error("할인금액을 입력해주세요");
                if (coupon.download.period && !coupon.download.startDate) throw new Error("혜택기간 시작일자를 입력해주세요");
                if (coupon.expiration.enabled) {
                    switch (coupon.expiration.type) {
                        case "dates": {
                            if (!coupon.expiration.startDate || !coupon.expiration.endDate) throw new Error("쿠폰유효기간을 설정해주세요");
                            break;
                        }
                        case "days": {
                            if (!coupon.expiration.days || coupon.expiration.days < 0) throw new Error("쿠폰유효기간을 설정해주세요");
                            break;
                        }
                    }
                }
                return true;
            } catch (error) {
                console.error(error);
                alert(error.message);
            }
            return false;
        },
        async save() {
            try {
                var coupon;
                switch (this.tab) {
                    case 0:
                        coupon = this.firstCoupon;
                        this.tab = 0;
                        break;
                    case 1:
                        coupon = this.secondCoupon;
                        this.tab = 1;
                        break;
                    case 2:
                        coupon = this.targetCoupon;
                        this.tab = 2;
                        break;
                }

                if (this.check(coupon)) {
                    await api.console.shop.coupons.postCoupon(coupon);

                    alert("저장되었습니다");
                    this.$router.go(-1);
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async searchUsers() {
            try {
                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.userPage - 1) * this.userLimit,
                        limit: this.userLimit,
                    },
                    params: this.userFilter,
                });

                this.users = users;
                this.userPageCount = Math.ceil(summary.totalCount / this.userLimit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        getTerm(startDate, endDate) {
            var date1 = new Date(startDate);
            var date2 = new Date(endDate);
            date1.addDate(-1);
            if (new Date(date1).addDate(7) == date2.getTime()) return 0.25;
            else if (new Date(date1).addMonth(1) == date2.getTime()) return 1;
            else if (new Date(date1).addMonth(3) == date2.getTime()) return 3;
            else if (new Date(date1).addMonth(6) == date2.getTime()) return 6;
            else if (new Date(date1).addYear(1) == date2.getTime()) return 12;
        },
        setTerm(value, target) {
            var startDate = new Date();
            var endDate = new Date();
            endDate.addDate(-1);
            switch (value) {
                case 0.25:
                    endDate.addDate(7);
                    break;
                case 1:
                    endDate.addMonth(1);
                    break;
                case 3:
                    endDate.addMonth(3);
                    break;
                case 6:
                    endDate.addMonth(6);
                    break;
                case 12:
                    endDate.addYear(1);
                    break;
            }
            target.startDate = startDate.getTime().toDate();
            target.endDate = endDate.getTime().toDate();
        },
    },
    watch: {
        searchValue() {
            if (this.products.length > 0) return;
            this.productLoading = true;
            api.console.shop.products
                .getProducts({ params: { searchKey: "name", searchValue: this.searchValue } })
                .then(({ products }) => {
                    this.products = products;
                })
                .finally(() => {
                    this.productLoading = false;
                });
        },
        usersSelected() {
            this.targetCoupon._users = this.usersSelected.map((user) => {
                return user._id;
            });
        },
    },
};
</script>

<style>
.shop-coupon-view .weight input {
    text-align: end;
}
.shop-coupon-view .amount input {
    text-align: end;
}
.shop-coupon-view .birth input {
    text-align: center;
}
.shop-coupon-view .theme--light.v-tabs-items {
    background-color: transparent !important;
}
</style>
