<template>
    <view-section-card title="도서산간설정 (사용/미사용)" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="islandsEnabled" :value="true" v-bind="attrs_switch" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="islandsEnabled">
                <v-divider />
                <v-card-text>
                    <v-row v-for="island in islands" :key="island._id">
                        <v-col cols="12">
                            <v-text-field v-model="island.price" :label="`'${island.name}' 지역 추가배송비`" persistent-placeholder dense hide-details @change="input"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_switch } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data: () => {
        return {
            islandsEnabled: false,

            islands: [],
            attrs_switch,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getIslands();

                var { islandsEnabled, islands } = this.value;

                if (islandsEnabled === undefined) islandsEnabled = this.islandsEnabled;
                if (islands === undefined) islands = this.islands;

                Object.assign(this.$data, {
                    islandsEnabled,
                });

                if (this.value.islands) {
                    for (var i in this.islands) {
                        var island = this.value.islands.find((island) => this.islands[i].code == island.code);
                        if (island) {
                            Object.assign(this.islands[i], island);
                        } else {
                            Object.assign(this.islands[i], { price: 0 });
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        input() {
            this.$set(this.value, "islandsEnabled", this.islandsEnabled);
            this.$set(this.value, "islands", this.islands);

            this.$emit("input", this.value);
        },
        getIslands() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/shop/islands");

                    this.islands = res.data.islands;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
