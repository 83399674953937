var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("검색엔진최적화(SEO) (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.seoEnabled,
      callback: function ($$v) {
        _vm.seoEnabled = $$v;
      },
      expression: "seoEnabled"
    }
  })], 1)], 1)], 1), _vm.seoEnabled ? _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.seo, "title", $$v);
      },
      expression: "seo.title"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.author.content,
      callback: function ($$v) {
        _vm.$set(_vm.author, "content", $$v);
      },
      expression: "author.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.decription.content,
      callback: function ($$v) {
        _vm.$set(_vm.decription, "content", $$v);
      },
      expression: "decription.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.keywords.content,
      callback: function ($$v) {
        _vm.$set(_vm.keywords, "content", $$v);
      },
      expression: "keywords.content"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }