<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="미결제 관리" />

            <v-card outlined>
                <v-card-text> 구매자가 나중에결제/무통장입금으로 주문한 이후, 아직 결제 완료되지 않은 주문건입니다. </v-card-text>
            </v-card>

            <v-data-table v-model="selected" v-bind="{ items, headers, loading }" :items-per-page="-1" show-select disable-pagination disable-sort hide-default-footer class="v-sheet--outlined mt-3">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
                <template #[`item.order.receiver.address`]="{ item }">
                    <div>[{{ item.order.receiver.postcode }}]</div>
                    <div>{{ item.order.receiver.address1 }} {{ item.order.receiver.address2 }}</div>
                </template>
                <template #[`item.order.account`]="{ item }">
                    <div>은행: {{ item.order.account?.accountBankText || "-" }} / {{ item.order.account.accountNumber || "-" }}</div>
                    <div>입금자: {{ item.order.account.accountHolder || "-" }}</div>
                    <div>금액: {{ item.order.totalPrice.format() }}원</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-card outlined>
                <v-card-text class="pa-0">
                    <v-row align="center" class="ma-0">
                        <v-col cols="12" sm="3" md="2">주문처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="pt-0 pt-sm-3">
                            <list-order-payment-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                            <list-order-cancel-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES } from "@/assets/variables";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ListOrderPaymentBtn from "@/components/console/shop/purchases/list-actions/list-order-payment-btn.vue";
import ListOrderCancelBtn from "@/components/console/shop/purchases/list-actions/list-order-cancel-btn.vue";

const headers = [
    { width: 190, text: "주문번호\n상품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, text: "주문상태", value: "orderStatusMessage" },
    { width: 100, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, text: "연락처", value: "user.phone" },
    { width: 200, text: "구매상품", value: "product.name" },
    { width: 200, text: "옵션정보", value: "name" },
    { width: +90, text: "구매수량", value: "amount" },
    { width: 120, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
    { width: 300, text: "배송지", value: "order.receiver.address" },
    { width: 200, text: "입금정보", value: "order.account" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    components: {
        OrderView,

        ListHeading,

        ListOrderPaymentBtn,
        ListOrderCancelBtn,
    },
    data: () => ({
        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        loading: false,

        selected: [],
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.orderStatus = [PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value];
            query.claimStatus = [null, PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value];

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
