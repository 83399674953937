<template>
    <component :is="display" />
</template>

<script>
import Dashboard from "@/pages/console/Dashboard.vue";
import AdminList from "./admins/AdminList.vue";

export default {
    components: {
        Dashboard,
        AdminList
    },
    methods: {
        scopeIncludes(criterion) {
            return this.payload?.scope?.includes?.(criterion);
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
        display() {
            if (this.scopeIncludes("console")) return AdminList;
        },
    },
};
</script>
