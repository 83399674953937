<template>
    <v-dialog v-model="shows" max-width="85vh" width="85%" :disabled="!src" content-class="black">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
            <v-avatar v-if="!hideActivator" v-bind="{ ...attrs, ...attrs_avatar }" v-on="on" :style="!src ? 'cursor:initial' : ''">
                <v-img :src="src" alt="" v-bind="$attrs">
                    <template v-slot:placeholder>
                        <v-icon dark>mdi-image-off</v-icon>
                    </template>
                </v-img>
            </v-avatar>
        </template>
        {{ $slots["activator"] }}

        <v-img v-if="src" :src="src" max-height="85vh" contain>
            <v-btn absolute right top icon tile dark x-large @click="shows = false" style="background-color: rgba(0, 0, 0, 0.3)">
                <v-icon size="4rem">mdi-close</v-icon>
            </v-btn>
        </v-img>
    </v-dialog>
</template>

<script>
export default {
    props: {
        src: { type: String, default: undefined },
        attrs_avatar: { type: Object, default: () => ({}) },
        hideActivator: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
    }),
};
</script>

<style></style>
