<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div class="headline text-start mt-8">관리자 상세</div>

            <v-card outlined class="mt-3">
                <v-card-title class="subtitle-2 font-weight-bold">회원정보</v-card-title>
                <v-card-text>

                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.username" label="아이디" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.password" label="비밀번호" v-bind="attrs_input" />
                        </v-col>
                    </v-row>
                    
                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.name" label="이름" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.phone" label="연락처" v-bind="attrs_input" />
                        </v-col>
                    </v-row>

                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.email" label="이메일" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" v-bind="attrs_input" />
                        </v-col>
                    </v-row>

                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.postcode" label="우편번호" v-bind="attrs_input" />
                        </v-col>
                    </v-row>

                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.address1" label="기본주소" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.address2" label="상세주소" v-bind="attrs_input" />
                        </v-col>
                    </v-row>

                    <v-row class="mx-n2">
                        <v-col cols="12" sm="6" class="pa-2">
                            <v-text-field v-model="user.ci" label="ci" v-bind="attrs_input" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- <v-card outlined class="mt-3">
                <v-card-title>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0 subtitle-1">주의회원</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="py-0">
                            <v-switch v-model="user.isWarning" v-bind="attrs_switch" color="red lighten-1" />
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card> -->

            <!-- <v-data-table v-bind="{ items, headers }" class="v-sheet--outlined mt-3">
                <template #top>
                    <v-card-title class="subtitle-2 font-weight-bold">주문내역</v-card-title>
                    <v-divider />
                </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div>{{ item.order.sender.name }}</div>
                    <div>{{ item.order.sender.phone }}</div>
                    <div>{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
            </v-data-table> -->

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";

const headers = [
    { width: 190, divider: true, text: "주문번호\n상품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, divider: true, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, divider: true, text: "주문상태", value: "orderStatusMessage" },
    { width: 120, divider: true, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, divider: true, text: "연락처", value: "order.sender.phone" },
    { width: 200, divider: true, text: "구매상품", value: "product.name" },
    { width: 200, divider: true, text: "옵션정보", value: "name" },
    { width: 100, divider: true, text: "구매수량", value: "amount" },
    { width: 120, divider: true, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

export default {
    components: {
        OrderView,
    },
    data: () => ({
        user: {
            _id: null,
            username: null,
            name: null,
            phone: null,
            email: null,
            _level: null,
            password: null,
            postcode: null,
            address1: null,
            address2: null,
            scope: ["console"]
        },
        levels: [],

        purchases: [],

        headers,
        attrs_input,
        attrs_switch,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._admin) {
                    var { user } = await api.console.users.get({ _id: this.$route.params._admin });
                    this.user = user;
                }

            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async save() {
            try {
                this.$route.params._admin ? await api.console.users.put(this.user) : await api.console.users.post(this.user);

                this.$route.params._admin ? alert("수정되었습니다") : alert("저장되었습니다")
                this.$router.push("/dhbizadm/admins")
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
