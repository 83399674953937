<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="주문 목록" v-model="showsSearch" showsFilterButton />
            <order-list-search v-bind="{ showsSearch }" />
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white green--text" class=" px-2" @click="excel">
                        <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table :items="orders" :headers="ordersHeaders" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template #[`item.no`]="{item}">
                    {{ (page - 1) * limit + orders.indexOf(item) + 1 }}
                </template>
				<template #[`item.totalAmount`]="{ item }">
                    {{  item.totalAmount.format()  }}원
                </template>
				<template #[`item.buyer`]="{ item }">
                    <div>{{ `${item.buyer.name}(${item.buyer.username})` }}</div>
					<div>{{ item.buyer.phone }}</div>
					<div>{{ item.buyer.email }}</div>
                </template>
				<template #[`item.faceamt`]="{ item }">
                    {{  item.faceamt.format() }}원
                </template>
				<template #[`item.quantity`]="{ item }">
                    {{  item.quantity.format() }}개
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{  $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`/dhbizadm/gshop/orders/${item._id}`">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
        <edit-dialog-user-point ref="userPoint" @init="search"/>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_switch } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
import OrderListSearch from "@/components/console/gshop/orders/order-list-search.vue";
import EditDialogUserType from "@/components/console/users/edit-dialog-user-type.vue";
import EditDialogUserPoint from "@/components/console/users/edit-dialog-user-point.vue";

export default {
    components: {
        ListHeading,
        UserListSearch,
        OrderListSearch,
        EditDialogUserType,
        EditDialogUserPoint,
    },
    data: () => ({
        orders: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        ordersHeaders: [
            { text: "NO", value: "no", divider: true, width: 50 },
            { text: "주문번호", value: "orderNo", divider: true, width: 160 },
            { text: "주문명", value: "orderName", divider: true, width: 160 },
            { text: "권종", value: "faceamt", divider: true, width: 160 },
            { text: "수량", value: "quantity", divider: true, width: 160 },
            { text: "결제금액", value: "totalAmount", divider: true, width: 100 },
            { text: "구매자", value: "buyer", divider: true, width: 120 },
            { text: "구매일", value: "createdAt", divider: true, width: 170 },
            { text: "", value: "actions", align: "center", width: +60,  },
        ],
        attrs_switch,
    }),
    computed: {
        items() {
            return this.orders.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.orders = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
				let { summary, orders } = await api.console.gshop.orders.gets({ headers: { skip, limit }, params })
				console.log(orders)

                this.orders = orders;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async excel() {
            try {
                const { params } = this;
                let { orders } = await api.console.gshop.orders.gets({ params })

                var rows = orders.map((order) => ({
                    주문번호: order?.orderNo,
                    주문명: order?.orderName,
                    권종: order?.faceamt?.format(),
                    수량: order?.quantity?.format(),
                    결제금액: order?.totalAmount?.format(),
                    "구매자 이름": order?.buyer?.name,
                    "구매자 번호": order?.buyer?.phone,
                    "구매자 아이디": order?.buyer?.username,
                    구매일: this.$dayjs(order?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "주문 목록.xlsx");
            } catch(error) {
                alert(error.message)
            }
        },
    },
};
</script>
