var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.isCreate ? '상품 등록' : '상품 상세'
    }
  }), _c('view-section-card', _vm._b({
    attrs: {
      "title": "카테고리"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('shop-category-select', {
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.product._category = _vm.product.category._id;
      }
    },
    model: {
      value: _vm.product.category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "category", $$v);
      },
      expression: "product.category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-n2 my-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "썸네일"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('image-input', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "flat": ""
    },
    model: {
      value: _vm.product.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.product, "thumb", $$v);
      },
      expression: "product.thumb"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "9"
    }
  }, [_c('view-section-card', _vm._b({
    staticClass: "fill-height",
    attrs: {
      "title": "상품 이미지"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('images-input', {
    attrs: {
      "md": 2
    },
    model: {
      value: _vm.product.images,
      callback: function ($$v) {
        _vm.$set(_vm.product, "images", $$v);
      },
      expression: "product.images"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "상품정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상품명"
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "판매가 (0원 일때 '무료'로 문구변경)",
      "suffix": "원"
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "재고",
      "disabled": _vm.product.optionsEnabled
    },
    model: {
      value: _vm.product.stock,
      callback: function ($$v) {
        _vm.$set(_vm.product, "stock", $$v);
      },
      expression: "product.stock"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "진열순서"
    },
    model: {
      value: _vm.product.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sequence", $$v);
      },
      expression: "product.sequence"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제조사"
    },
    model: {
      value: _vm.product.manufacturer,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manufacturer", $$v);
      },
      expression: "product.manufacturer"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "원산지"
    },
    model: {
      value: _vm.product.origin,
      callback: function ($$v) {
        _vm.$set(_vm.product, "origin", $$v);
      },
      expression: "product.origin"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', _vm._b({
    attrs: {
      "label": "해시태그",
      "multiple": "",
      "dense": false,
      "append-icon": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.product.hashtags,
      callback: function ($$v) {
        _vm.$set(_vm.product, "hashtags", $$v);
      },
      expression: "product.hashtags"
    }
  }, 'v-combobox', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    attrs: {
      "label": "리뷰 키워드",
      "items": _vm.product.meta.reviews.keywords,
      "multiple": "",
      "dense": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          item = _ref2.item,
          parent = _ref2.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.product.keywords,
      callback: function ($$v) {
        _vm.$set(_vm.product, "keywords", $$v);
      },
      expression: "product.keywords"
    }
  }, 'v-autocomplete', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "브랜드",
      "actionCols": "6",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-select', _vm._b({
          staticClass: "my-n3",
          attrs: {
            "items": _vm.brands,
            "item-text": "name",
            "item-value": "_id"
          },
          model: {
            value: _vm.product._brand,
            callback: function ($$v) {
              _vm.$set(_vm.product, "_brand", $$v);
            },
            expression: "product._brand"
          }
        }, 'v-select', _vm.attrs_input, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('view-section-title', {
    attrs: {
      "title": "전시여부 (전시/숨김)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.display,
            callback: function ($$v) {
              _vm.$set(_vm.product, "display", $$v);
            },
            expression: "product.display"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('view-section-title', {
    attrs: {
      "title": "판매여부 (판매/중단)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.sale,
            callback: function ($$v) {
              _vm.$set(_vm.product, "sale", $$v);
            },
            expression: "product.sale"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('view-section-title', {
    attrs: {
      "title": "품절여부 (품절/판매)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.soldout,
            callback: function ($$v) {
              _vm.$set(_vm.product, "soldout", $$v);
            },
            expression: "product.soldout"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-discount', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-discount', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('view-section-title', {
    attrs: {
      "title": "신상품 (사용/미사용)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.newly,
            callback: function ($$v) {
              _vm.$set(_vm.product, "newly", $$v);
            },
            expression: "product.newly"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('view-section-title', {
    attrs: {
      "title": "특가 (판매/중단)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.special,
            callback: function ($$v) {
              _vm.$set(_vm.product, "special", $$v);
            },
            expression: "product.special"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('view-section-title', {
    attrs: {
      "title": "베스트 (품절/판매)"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.product.best,
            callback: function ($$v) {
              _vm.$set(_vm.product, "best", $$v);
            },
            expression: "product.best"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-options', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-options', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-supplies', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-supplies', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-shippings', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-shippings', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-islands', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-islands', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-seo', _vm._b({
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-seo', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "상품 상세설명"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('naver-smarteditor', {
    staticClass: "mb-n2",
    attrs: {
      "rows": "10"
    },
    model: {
      value: _vm.product.content,
      callback: function ($$v) {
        _vm.$set(_vm.product, "content", $$v);
      },
      expression: "product.content"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "large": "",
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }