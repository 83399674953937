var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "tit tit--lg",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._ip ? _c('span', [_vm._v("IP 상세")]) : _c('span', [_vm._v("IP 생성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs"
  }, [_vm._v("IP정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.whiteIp.name,
      callback: function ($$v) {
        _vm.$set(_vm.whiteIp, "name", $$v);
      },
      expression: "whiteIp.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "IPv4 address",
      "placeholder": "예) 127.0.0.1",
      "rules": _vm.rules,
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.whiteIp.ip,
      callback: function ($$v) {
        _vm.$set(_vm.whiteIp, "ip", $$v);
      },
      expression: "whiteIp.ip"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.whiteIp.phone,
      callback: function ($$v) {
        _vm.$set(_vm.whiteIp, "phone", $$v);
      },
      expression: "whiteIp.phone"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "사유",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.whiteIp.reason,
      callback: function ($$v) {
        _vm.$set(_vm.whiteIp, "reason", $$v);
      },
      expression: "whiteIp.reason"
    }
  })], 1)], 1)], 1), _c('v-card-actions')], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs font-weight-bold"
  }, [_vm._v("관리자 메모")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "rows": "2"
    },
    model: {
      value: _vm.whiteIp.note,
      callback: function ($$v) {
        _vm.$set(_vm.whiteIp, "note", $$v);
      },
      expression: "whiteIp.note"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }