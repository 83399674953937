var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "85vh",
      "width": "85%",
      "disabled": !_vm.src,
      "content-class": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        }), !_vm.hideActivator ? _c('v-avatar', _vm._g(_vm._b({
          style: !_vm.src ? 'cursor:initial' : ''
        }, 'v-avatar', Object.assign({}, attrs, _vm.attrs_avatar), false), on), [_c('v-img', _vm._b({
          attrs: {
            "src": _vm.src,
            "alt": ""
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "dark": ""
                }
              }, [_vm._v("mdi-image-off")])];
            },
            proxy: true
          }], null, true)
        }, 'v-img', _vm.$attrs, false))], 1) : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm._v(" " + _vm._s(_vm.$slots["activator"]) + " "), _vm.src ? _c('v-img', {
    attrs: {
      "src": _vm.src,
      "max-height": "85vh",
      "contain": ""
    }
  }, [_c('v-btn', {
    staticStyle: {
      "background-color": "rgba(0, 0, 0, 0.3)"
    },
    attrs: {
      "absolute": "",
      "right": "",
      "top": "",
      "icon": "",
      "tile": "",
      "dark": "",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "4rem"
    }
  }, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }