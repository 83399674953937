<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div class="headline text-start mt-8">회원 상세</div>
            <v-row class="mt-2">
                <v-col cols="12" md="8" class="pr-md-1">
                    <v-card outlined class="mt-3">
                        <v-card-title class="subtitle-2 font-weight-bold">회원정보</v-card-title>
                        <v-card-text>
    
                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.username" label="아이디" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.password" label="비밀번호" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
    
                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.name" label="이름" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.phone" label="연락처" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
    
                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.email" label="이메일" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
    
                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.postcode" label="우편번호" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
    
                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.address1" label="기본주소" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.address2" label="상세주소" v-bind="attrs_input" />
                                </v-col>
                            </v-row>

                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.company.businessNo" label="사업자번호" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.company.name" label="회사이름" v-bind="attrs_input" />
                                </v-col>
                            </v-row>

                            <v-row class="mx-n2">
                                <v-col cols="12" sm="6" class="pa-2">
                                    <v-text-field v-model="user.company.ceo" label="ceo" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pl-md-1">
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">로그인 실패횟수 ({{ user.loginFailedCount[$dayjs().format("YYYY-MM-DD")] || 0 }}/5)</span>
                            <v-spacer/>
                            <v-icon size="20" @click="user.loginFailedCount = new Map()">mdi-refresh</v-icon>
                        </v-card-title>
                    </v-card>
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">승인여부 (미승인/승인)</span>
                            <v-spacer/>
                            <v-switch v-model="user.isApproval" hide-details class="mt-0"/>
                        </v-card-title>
                    </v-card>
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">강제탈퇴 (탈퇴X/탈퇴)</span>
                            <v-spacer/>
                            <v-switch v-model="user.isWithdrawn" hide-details class="mt-0"/>
                        </v-card-title>
                    </v-card>
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">차단여부 (차단안함/차단)</span>
                            <v-spacer/>
                            <v-switch v-model="user.isBlock" hide-details class="mt-0"/>
                        </v-card-title>
                    </v-card>
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">휴면여부 (휴면안함/휴면)</span>
                            <v-spacer/>
                            <v-switch v-model="user.isDormancy" hide-details class="mt-0"/>
                        </v-card-title>
                    </v-card>
                    <v-card outlined class="mt-3">
                        <v-card-title>
                            <span class="tit tit--xs">사업자등록증 다운로드</span>
                            <v-spacer/>
                            <a :href="user.company.businessLicense" download><v-icon>mdi-arrow-down-bold-box-outline</v-icon></a>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <v-card outlined class="mt-3">
                <v-card-title class="tit tit--xs font-weight-bold">관리자 메모</v-card-title>
                <v-card-text>
                    <v-textarea v-model="user.note" outlined hide-details rows="2" />
                </v-card-text>
            </v-card>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";

const headers = [
    { width: 190, divider: true, text: "주문번호\n상품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, divider: true, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, divider: true, text: "주문상태", value: "orderStatusMessage" },
    { width: 120, divider: true, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, divider: true, text: "연락처", value: "order.sender.phone" },
    { width: 200, divider: true, text: "구매상품", value: "product.name" },
    { width: 200, divider: true, text: "옵션정보", value: "name" },
    { width: 100, divider: true, text: "구매수량", value: "amount" },
    { width: 120, divider: true, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

export default {
    components: {
        OrderView,
    },
    data: () => ({
        user: {},
        levels: [],

        purchases: [],

        headers,
        attrs_input,
        attrs_switch,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                this.user = user;

            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async save() {
            try {
                await api.console.users.put(this.user);

                alert("수정되었습니다");
                this.$router.push("/dhbizadm/users")
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>
