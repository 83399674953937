<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">구매후기</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                        <v-card-text>
                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAts[0]" label="등록일자" persistent-placeholder dense hide-details></v-date-field>
                                </v-col>

                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAts[1]" label="등록일자" persistent-placeholder dense hide-details></v-date-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col cols="12" md="3" class="py-0">
                                    <v-select v-model="filter.searchKey" label="검색옵션" :items="searchKeyItems" item-text="text" item-value="value" persistent-placeholder dense hide-details></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="py-0">
                                    <v-text-field v-model="filter.searchValue" label="검색어" :disabled="!filter.searchKey" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-row class="mt-6">
                    <v-col class="py-0">
                        <span class="subtitle-2">검색수 : {{ summary.totalCount.format() }}개</span>
                    </v-col>
                </v-row>
                <v-data-table :headers="reviewsHeaders" :items="reviews" disable-sort disable-pagination hide-default-footer class="elevation-1">
                    <template v-slot:[`item.createdAt`]="{item}">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template v-slot:[`item.product`]="{ item }">
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-img :src="`/res/shop/reviews/${item._id}/${item.images[0]}`" contain max-height="96" max-width="96"></v-img>
                            </v-col>
                            <v-col class="text-start">
                                {{ item.product.name }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.user`]="{ item }">
                        <div v-if="item.user">{{ item.user.name }}</div>
                        <div v-if="item.user">{{ item.user.username }}</div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

                
                <v-dialog v-model="reviewDialog" max-width="960px">
                    <v-card v-if="reviewDialog">
                        <v-card-title>
                            <span class="subtitle-1">상품리뷰</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="editItem.subject" label="리뷰 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                            <v-text-field label="리뷰날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>

                            <div class="caption mt-4">리뷰내용</div>
                            <div class="body-2" v-html="editItem.content"></div>
                            <v-row v-if="editItem.images && editItem.images.filter(image => image)">
                                <v-col v-for="(image, i) in editItem.images" :key="`image-${i}`" cols="auto">
                                    <v-dialog max-width="100%" width="unset">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="`/res/shop/reviews/${editItem._id}/${image}`" max-width="96" max-height="96" contain v-on="on"></v-img>
                                        </template>

                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-img :src="`/res/shop/reviews/${editItem._id}/${image}`" contain>
                                                    <v-system-bar lights-out dark class="pa-0">
                                                        <v-spacer></v-spacer>
                                                        <v-icon @click="dialog.value = false">mdi-close</v-icon>
                                                    </v-system-bar>
                                                </v-img>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <div class="caption mt-4">답변</div>
                            <naver-smarteditor v-model="editItem.reply"></naver-smarteditor>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        VDateField,
        NaverSmarteditor
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,
            filter: {
                createdAts: [],
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null
            },

            searchKeyItems: [
                { text: "전체", value: null },
                { text: "작성자명", value: "user.name" },
                { text: "회원아이디", value: "user.username" },
                { text: "연락처", value: "user.phone" },
                { text: "상품명", value: "product.name" }
            ],

            ///////////////////////////////////////////////
            // 페이징
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            ///////////////////////////////////////////////
            // 리뷰목록
            ///////////////////////////////////////////////
            summary: { totalCount: 0 },
            reviews: [],
            reviewsHeaders: [{
                text: '상품',
                align: 'center',
                value: 'product'
            }, {
                text: '후기내용',
                align: 'center',
                value: 'content'
            }, {
                text: '작성자',
                align: 'center',
                width: 140,
                value: 'user'
            }, {
                text: '작성일자',
                align: 'center',
                width: 170,
                value: 'createdAt'
            }, {
                text: 'Actions',
                align: 'center',
                value: 'actions'
            }],

            reviewDialog: false,
            editItem: {}
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async search(){
            try{
                var { summary, reviews } = await api.console.shop.reviews.getReviews({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.reviews = reviews;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        edit(review){
            this.editItem = Object.assign({}, review);
            this.reviewDialog = true;
        },

        close(){
            this.reviewDialog = false;
        },

        async save(){
            try{
                await api.console.shop.reviews.putReview(this.editItem);
                await this.search();
                alert("저장되었습니다");
                this.reviewDialog = false;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>
