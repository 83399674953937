<template>
<v-container fluid fill-height>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%">
            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <v-card>
                        <v-toolbar color="info lighten-1" dense>
                            <v-toolbar-title class="white--text subtitle-2">카테고리</v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" width="560">
                                <template v-slot:activator="{ on }">
                                    <v-fab-transition>
                                        <v-btn color="accent" fab dark small absolute bottom right v-on="on">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-fab-transition>
                                </template>

                                <v-card v-if="dialog">
                                    <v-card-title class="subtitle-1" primary-title>신규 카테고리 등록</v-card-title>

                                    <v-card-text>
                                        <v-row class="mt-3">
                                            <v-col class="py-0">
                                                <shop-category-select v-if="categories" v-model="editItem.parent" :categories="categories"></shop-category-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-6">
                                            <v-col class="py-0">
                                                <v-text-field v-model="editItem.name" label="카테고리 이름" persistent-placeholder dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-6">
                                            <v-col class="py-0">
                                                <v-text-field v-model="editItem.code" label="카테고리 코드" persistent-placeholder dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row align="center" class="mt-3">
                                            <v-col cols="auto"><span>검색엔진최적화(SEO)</span> <span>(사용/미사용)</span></v-col>
                                            <v-spacer/>
                                            <v-col cols="auto">
                                                <v-switch v-model="editItem.seoEnabled" dense hide-details class="pa-0 ma-0"></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="editItem.seoEnabled">
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-text-field v-model="editItem.seo.title" label="타이틀: Title" persistent-placeholder dense hide-details class="mt-3" @input="input"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-text-field v-model="editItem.seo.meta.find((meta) => meta.name == 'author').content" label="메타태그: Author" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-textarea v-model="editItem.seo.meta.find((meta) => meta.name == 'description').content" label="메타태그: Description" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-textarea>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-textarea v-model="editItem.seo.meta.find((meta) => meta.name == 'keywords').content" label="메타태그: Keywords" persistent-placeholder dense hide-details class="mt-6" @input="input"></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="dialog = false">취소</v-btn>
                                        <v-btn color="primary" text @click="save">등록</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>

                        <v-card-text>
                            <v-draggable-treeview v-if="categories.length" v-model="categories" item-key="_id" children="children" group="categories" @click="select" dense></v-draggable-treeview>
                            <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                        </v-card-text>

                    </v-card>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col md="auto">

                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card :disabled="!category._id">
                        <v-card-title class="subtitle-1">카테고리 기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.code" label="카테고리 코드(영문)" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.name" label="카테고리 이름" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <shop-category-seo v-model="category" :disabled="!category._id"></shop-category-seo>

                    <v-row>
                        <v-col md="auto">
                            <v-btn color="grey darken-1" class="white--text" @click="remove(category)" :disabled="!category._id || category.root ? true : false">삭제</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col md="auto">
                            <v-btn color="primary" @click="update(category)" :disabled="!category._id">저장</v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>

</v-container>
</template>

<script>
import api from "@/api";
import ShopCategorySelect from '@/components/console/shop/categories/shop-category-select.vue';
import ShopCategorySeo from '@/components/console/shop/categories/shop-category-seo.vue';

export default {
    components: {
        ShopCategorySelect,
        ShopCategorySeo
    },
    data: () => {
        return {
            dialog: false,

            loading: true,

            headers: [],
            categories: [],
            category: {},
            editItem: null,
            defaultItem: {
                parent: null,
                name: "",
                no: 0,
                type: "",
                seoEnabled: false,
                seo: {
                    title: null,
                    meta: [
                        { name: "author", content: null },
                        { name: "description", content: null },
                        { name: "keywords", content: null },
                    ]
                }
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
            this.loading = false;
        },

        async search(){
            try{
                var { categories } = await api.console.shop.categories.getCategories();
                this.categories = categories;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message );
            }
        },

        /////////////////////////////////////////
        // 카테고리 등록
        /////////////////////////////////////////
        async save() {
            try {
                this.loading = true;

                var { category } = await api.console.shop.categories.postCategory(
                    Object.assign({}, this.editItem, {
                        _parents: this.editItem.parent ? [].concat(this.editItem.parent._parents, this.editItem.parent._id) : [],
                        depth: this.editItem.parent ? this.editItem.parent.depth + 1 : 1,
                    })
                );

                await this.search();
                this.dialog = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        /////////////////////////////////////////
        // 카테고리 수정
        /////////////////////////////////////////
        async update(category) {
            try {
                this.loading = true;

                await api.console.shop.categories.putCategory(category);
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        /////////////////////////////////////////
        // 카테고리 삭제
        /////////////////////////////////////////
        async remove(category) {
            try {
                this.loading = true;

                await api.console.shop.categories.deleteCategory(category);
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        findParent(category, parent) {
            for (var i in parent.categories) {
                if (parent.categories[i] == category) return parent;
                if (parent.categories[i].categories) {
                    var result = this.findParent(category, parent.categories[i]);
                    if (result) return result;
                }
            }
            return null;
        },

        select(category) {
            this.category = category;
        },

        sort(categories, _parents = [], depth = 1) {
            for(var i in categories) {
                var category = categories[i];
                category.sequence = i;
                category.depth = depth;
                category._parents = _parents;

                if (category.children) {
                    category._children = category.children.map((child) => { return child._id });
                    this.sort(category.children, [].concat(category._parents, category._id), depth + 1);
                }
                api.console.shop.categories.putCategory(category);
            }
        }
    },
    watch: {
        dialog(value){
            if(value) this.editItem = Object.assign({}, this.defaultItem);
        },
        categories() {
            if(!this.loading) this.sort(this.categories);
        }
    }
}
</script>
