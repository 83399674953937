var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("회원 상세")]), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "pr-md-1",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("회원정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디"
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일"
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "회원등급",
      "items": _vm.levels,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.user._level,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_level", $$v);
      },
      expression: "user._level"
    }
  }, 'v-select', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우편번호"
    },
    model: {
      value: _vm.user.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "postcode", $$v);
      },
      expression: "user.postcode"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기본주소"
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상세주소"
    },
    model: {
      value: _vm.user.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address2", $$v);
      },
      expression: "user.address2"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사업자번호"
    },
    model: {
      value: _vm.user.company.businessNo,
      callback: function ($$v) {
        _vm.$set(_vm.user.company, "businessNo", $$v);
      },
      expression: "user.company.businessNo"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "회사이름"
    },
    model: {
      value: _vm.user.company.name,
      callback: function ($$v) {
        _vm.$set(_vm.user.company, "name", $$v);
      },
      expression: "user.company.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "ceo"
    },
    model: {
      value: _vm.user.company.ceo,
      callback: function ($$v) {
        _vm.$set(_vm.user.company, "ceo", $$v);
      },
      expression: "user.company.ceo"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("로그인 실패횟수 (" + _vm._s(_vm.user.loginFailedCount[_vm.$dayjs().format("YYYY-MM-DD")] || 0) + "/5)")]), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "size": "20"
    },
    on: {
      "click": function ($event) {
        _vm.user.loginFailedCount = new Map();
      }
    }
  }, [_vm._v("mdi-refresh")])], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("승인여부 (미승인/승인)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.user.isApproval,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isApproval", $$v);
      },
      expression: "user.isApproval"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("강제탈퇴 (탈퇴X/탈퇴)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.user.isWithdrawn,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isWithdrawn", $$v);
      },
      expression: "user.isWithdrawn"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("차단여부 (차단안함/차단)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.user.isBlock,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isBlock", $$v);
      },
      expression: "user.isBlock"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("휴면여부 (휴면안함/휴면)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.user.isDormancy,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isDormancy", $$v);
      },
      expression: "user.isDormancy"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("사업자등록증 다운로드")]), _c('v-spacer'), _c('a', {
    attrs: {
      "href": _vm.user.company.businessLicense,
      "download": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-down-bold-box-outline")])], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs font-weight-bold"
  }, [_vm._v("관리자 메모")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "rows": "2"
    },
    model: {
      value: _vm.user.note,
      callback: function ($$v) {
        _vm.$set(_vm.user, "note", $$v);
      },
      expression: "user.note"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }