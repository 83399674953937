var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "주문 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('order-list-search', _vm._b({}, 'order-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "px-2",
    attrs: {
      "color": "white green--text"
    },
    on: {
      "click": _vm.excel
    }
  }, [_c('span', [_c('v-icon', [_vm._v("mdi-microsoft-excel")]), _vm._v(" 엑셀다운로드 ")], 1)])], 1)], 1), _c('v-data-table', {
    staticClass: "v-sheet--outlined",
    attrs: {
      "items": _vm.orders,
      "headers": _vm.ordersHeaders,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + _vm.orders.indexOf(item) + 1) + " ")];
      }
    }, {
      key: `item.totalAmount`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.totalAmount.format()) + "원 ")];
      }
    }, {
      key: `item.buyer`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(`${item.buyer.name}(${item.buyer.username})`))]), _c('div', [_vm._v(_vm._s(item.buyer.phone))]), _c('div', [_vm._v(_vm._s(item.buyer.email))])];
      }
    }, {
      key: `item.faceamt`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.faceamt.format()) + "원 ")];
      }
    }, {
      key: `item.quantity`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.quantity.format()) + "개 ")];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "to": `/dhbizadm/gshop/orders/${item._id}`
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1)];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1), _c('edit-dialog-user-point', {
    ref: "userPoint",
    on: {
      "init": _vm.search
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }