<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" class="px-3 mx-n3">
            <list-heading title="포인트 내역" showsFilterButton v-model="showsSearch" />
            <point-list-search v-bind="{ showsSearch }" />
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white green--text" class=" px-2" @click="excel">
                        <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.user`]="{ item }">
                    <div v-if="item.user">{{ item.user.username }}</div>
                    <div v-if="item.user">{{ item.user.name }}</div>
                    <div v-if="item.user">{{ (item.user.phone || "").phoneNumberFormat() }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <!-- <v-card outlined>
                <v-card-title class="subtitle-1">
                    <v-row align="center" no-gutters>
                        <v-col cols="auto" class="pr-3">적립금 적립</v-col>
                        <v-col cols="12" md="" class="caption primary--text">
                            + 금액 입력시 적립, - 금액 입력시 차감됩니다.
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" class="mx-n2 mb-n2">
                        <v-col cols="6" md="3" class="pa-2">
                            <autocomplete-user v-model="point._user" label="회원" :params="{ type: userTypes }" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="6" md="3" class="pa-2">
                            <v-currency-field v-model="point.amount" label="적립금" suffix="원" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" md="" class="pa-2">
                            <v-text-field v-model="point.remark" label="메모입력" v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" md="auto" class="pa-2">
                            <v-btn block color="primary" @click="save">적립하기</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import PointListSearch from "@/components/console/users/points/point-list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const headers = [
    { width: 164, text: "주문/사용 번호", value: "tno", formatter: (value) => value || value || "-" },
    { width: 164, text: "지급/사용일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 180, text: "사용자", value: "user", cellClass: "caption" },
    { width: 480, text: "비고", value: "remark" },
    { width: 115, text: "적립금액", value: "amount", formatter: (value) => (value?.format?.() || 0) + "원", align: "end" },
    { width: 115, text: "남은금액", value: "remain", formatter: (value) => (value?.format?.() || 0) + "원", align: "end" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1 }));

const userTypes = [USER_TYPES.CONSUMER.value, USER_TYPES.PROMOTER.value];

export default {
    components: {
        ListHeading,
        PointListSearch,
        AutocompleteUser,
    },
    data: () => ({
        points: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,

        attrs_input,

        point: {
            _user: null,
            amount: 0,
            remark: null,
        },
        userTypes,
    }),
    computed: {
        items() {
            return [...this.points];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, points } = await api.console.users.points.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.points = points;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.point._user) throw new Error("회원을 선택해주세요");
                if (!this.point.amount) throw new Error("적립금 지급량을 입력하세요");

                await api.console.users.points.postPoint(this.point);

                alert(this.amount < 0 ? "적립금이 차감되었습니다" : "적립금이 지급되었습니다");
            } finally {
                this.loading = false;
                this.search();
            }
        },
        async excel() {
            try {
                let { params } = this;
                var { points } = await api.console.users.points.gets({ params });

                var rows = [];
                points.forEach((point) => {
                    rows.push({
                        "주문/사용 번호": point?.tno || "-",
                        "지급/사용일시": point?.createdAt?.toDateTime() || "-",
                        이름: point?.user?.name || "-",
                        아이디: point?.user?.username || "-",
                        연락처: point?.user?.phone || "-",
                        비고: point?.remark || "-",
                        적립금액: point?.amount?.format() || "-",
                        남은금액: point?.remain?.format() || "-"
                    });
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "포인트 목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
