<template>
    <v-layout v-if="loading" justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="주문 목록" />

			<v-card class="mx-1 mt-4">
            <v-card-title class="subtitle-2 font-weight-bold">
				<span><v-icon color="blue" small>mdi-minus-thick</v-icon>기본정보</span>
                <v-spacer/>
                <span class="caption grey--text">주문번호</span>
                <v-divider vertical class="mx-2 my-1"/>
                <span class="body-2">{{ order?.orderNo }}</span>
            </v-card-title>
            <v-divider/>
            <v-data-table :items="[order]" :headers="ordersHeaders" hide-default-footer disable-sort class="mx-1">
                <template #[`item.faceamt`]="{item}">
                    {{ item?.faceamt?.format() }}원
                </template>
				<template #[`item.deliveryMethod`]="{item}">
                    <div v-if="item.deliveryMethod === 'KAKAO'">카카오 알림톡</div>
					<div v-else>문자</div>
                </template>
				<template #[`item.sendingMode`]="{item}">
                    <div v-if="item.sendingMode === 'IMMEDIATE'">즉시발송</div>
					<div v-else>예약발송</div>
                </template>
				<template #[`item.quantity`]="{item}">
                    {{ item?.quantity?.format() }}개
                </template>
            </v-data-table>
        </v-card>

        <v-card class="mt-4">
            <v-card-title class="subtitle-2 font-weight-bold">
				<span><v-icon color="blue" small>mdi-minus-thick</v-icon>구매자 정보</span></v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3" class="py-0">
                        <v-text-field :value="order.buyer.name" label="이름" placeholder=" " hide-details readonly />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                        <v-text-field :value="order.buyer.username" label="아이디" placeholder=" " hide-details readonly />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                        <v-text-field :value="order.buyer.phone" label="연락처" placeholder=" " hide-details readonly />
                    </v-col>
                    <v-col cols="12" md="3" class="py-0">
                        <v-text-field :value="order.buyer.email" label="이메일" placeholder=" " hide-details readonly />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row class="mt-6 px-1">
            <v-col cols="auto" class="py-0"></v-col>
            <v-spacer />
            <v-col cols="auto" class="py-0">
                <v-btn color="white" class="green--text px-2 ml-1">
					<span @click="excel"><v-icon>mdi-microsoft-excel</v-icon>엑셀다운로드</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="subtitle-2 font-weight-bold">
				<span><v-icon color="blue" small>mdi-minus-thick</v-icon>발행정보</span>
                <v-spacer/>
            </v-card-title>
            <v-divider/>
            <v-data-table :items="purchases" :headers="purchasesHeaders" class="mx-1">
				<template #[`item.no`]="{item}">
                    {{ purchases.indexOf(item) + 1 }}
                </template>
				<template #[`item.cno`]>
                    {{ order?.cno }}
                </template>
				<template #[`item.statusMessage`]="{ item }">
                    <div v-if="item.statusMessage === '발행'">미사용</div>
					<div v-if="item.statusMessage === '폐기'">폐기</div>
					<div v-if="item.statusMessage === '사용'">사용</div>
                </template>
				<template #[`item.note`]="{ item }">
                    <div class="text-end">
                        <p class="d-inline-block">{{ item.note }}</p>
                        <v-icon small @click="note(item)" class="d-inline-block ml-2">mdi-pencil</v-icon>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        </v-responsive>
		<note-dialog ref="noteDialog" v-on="{ init }"></note-dialog>
    </v-layout>
</template>

<script>
import api from "@/api"
import XLSX from "xlsx";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import NoteDialog from "@/components/console/gshop/orders/note-dialog.vue";

export default {
	components: {
		NoteDialog,
		ListHeading,
	},
	data() {
		return {
			loading: false,
			order: null,
			purchases: [],

            ordersHeaders: [
                { text: "주문명", value: "orderName", align: "center" },
                { text: "권종", value: "faceamt", align: "center" },
                { text: "수량", value: "quantity", align: "center" },
				{ text: "발송수단", value: "deliveryMethod", align: "center" },
				{ text: "발신유형", value: "sendingMode", align: "center" },
				{ text: "발신목적", value: "sendingPurpose", align: "center" }
            ],
			purchasesHeaders: [
				{ text: "no", value: "no", align: "center", width: 100 },
                { text: "발권주문번호", value: "purchaseNo", align: "center", width: 150 },
                { text: "포인트승인번호", value: "cno", align: "center", width: 150 },
				{ text: "수신인", value: "receiver.name", align: "center", width: 150 },
				{ text: "핸드폰번호", value: "receiver.phone", align: "center", width: 150 },
				{ text: "핀번호", value: "pin.pno", align: "center", width: 100 },
                { text: "핀상태", value: "statusMessage", align: "center", width: 100 },
                { text: "발송상태", value: "pin.sendStatus", align: "center", width: 100 },
                { text: "메모", value: "note", align: "center", width: 400, sortable: false },
			]
		}
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.loading = false;
			let { order } = await api.console.gshop.orders.get({ _id: this.$route.params._order });
			let { purchases } = await api.console.gshop.purchases.gets({ params: { _order: order._id }})
			this.order = order;
			this.purchases = purchases;
			this.loading = true;
		},
		note(purchase){
            this.$refs.noteDialog.purchase = purchase;
            this.$refs.noteDialog.open();
        },

		async excel() {

            try {
                let { purchases } = await api.console.gshop.purchases.gets({ params: { _order: this.order._id }})

                var rows = purchases.map((purchase) => ({
                    발권주문번호: purchase?.purchaseNo,
                    포인트승인번호: this?.order?.cno,
                    수신인: purchase?.receiver?.name,
                    핸드폰번호: purchase?.receiver?.phone,
                    핀번호: purchase?.pin?.pno,
                    핀상태: purchase?.statusMessage,
                    발송상태: purchase?.pin?.sendStatus,
                    메모: purchase?.note,
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "발행정보.xlsx");
            } catch(error) {
				alert(error.message)
			}
        },
	}
}
</script>

<style>

</style>