<template>
    <v-layout justify-center fill-height>
        <v-responsive max-width="1024px" width="100%" height="100%">
            <list-heading :title="isCreate ? '상품 등록' : '상품 상세'" />

            <view-section-card title="카테고리" v-bind="{ loading }">
                <v-card-text>
                    <shop-category-select v-model="product.category" :categories="categories" @input="product._category = product.category._id"></shop-category-select>
                </v-card-text>
            </view-section-card>

            <v-row class="mx-n2 my-2">
                <v-col cols="12" sm="3" class="pa-2">
                    <view-section-card title="썸네일" v-bind="{ loading }">
                        <v-card-text>
                            <image-input v-model="product.thumb" :aspect-ratio="1 / 1" flat />
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" sm="9" class="pa-2">
                    <view-section-card title="상품 이미지" v-bind="{ loading }" class="fill-height">
                        <v-card-text>
                            <images-input v-model="product.images" :md="2" />
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="6" class="pa-2">
                    <view-section-card title="상품정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="mx-n2">
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="product.name" label="상품명" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-currency-field v-model="product.price" label="판매가 (0원 일때 '무료'로 문구변경)" suffix="원" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-currency-field v-model="product.stock" label="재고" :disabled="product.optionsEnabled" v-bind="attrs_input" />
                                </v-col>
                                <!-- <v-col cols="12" class="pa-2">
                                    <v-currency-field v-model="product.cost" label="원가 (0원 이상시 할인율도 같이표시)" suffix="원" v-bind="attrs_input" />
                                </v-col> -->
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="product.sequence" label="진열순서" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="product.manufacturer" label="제조사" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="product.origin" label="원산지" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-combobox v-model="product.hashtags" label="해시태그" multiple :dense="false" append-icon="" v-bind="attrs_input">
                                        <template #selection="{ attrs, item, parent }">
                                            <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-autocomplete v-model="product.keywords" label="리뷰 키워드" :items="product.meta.reviews.keywords" multiple :dense="false" v-bind="attrs_input">
                                        <template #selection="{ attrs, item, parent }">
                                            <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                    <v-row class="ma-n2">
                        <v-col cols="12" class="pa-2">
                            <view-section-card title="브랜드" actionCols="6" hideDivider v-bind="{ loading }">
                                <template #actions> <v-select v-model="product._brand" :items="brands" item-text="name" item-value="_id" class="my-n3" v-bind="attrs_input"></v-select> </template>
                            </view-section-card>
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <view-section-title title="전시여부 (전시/숨김)">
                                    <template #actions> <v-switch v-model="product.display" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                                <v-divider />
                                <view-section-title title="판매여부 (판매/중단)">
                                    <template #actions> <v-switch v-model="product.sale" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                                <v-divider />
                                <view-section-title title="품절여부 (품절/판매)">
                                    <template #actions> <v-switch v-model="product.soldout" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                            </view-section-card>
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <!-- 보너스적립 -->
                            <!-- <shop-product-bonus v-model="product" style="margin-bottom: -1px" v-bind="{ loading }" /> -->

                            <!-- 할인사용 -->
                            <shop-product-discount v-model="product" v-bind="{ loading }" />
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <view-section-title title="신상품 (사용/미사용)">
                                    <template #actions> <v-switch v-model="product.newly" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                                <v-divider />
                                <view-section-title title="특가 (판매/중단)">
                                    <template #actions> <v-switch v-model="product.special" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                                <v-divider />
                                <view-section-title title="베스트 (품절/판매)">
                                    <template #actions> <v-switch v-model="product.best" v-bind="attrs_switch" /> </template>
                                </view-section-title>
                            </view-section-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-row class="ma-n2">
                        <!-- 상품옵션 -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-options v-model="product" v-bind="{ loading }" />
                        </v-col>

                        <!-- 추가상품 -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-supplies v-model="product" v-bind="{ loading }" />
                        </v-col>

                        <!-- 배송비 -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-shippings v-model="product" v-bind="{ loading }" />
                        </v-col>

                        <!-- 도서산간 -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-islands v-model="product" v-bind="{ loading }" />
                        </v-col>

                        <!-- 검색엔진최적화(SEO) -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-seo v-model="product" v-bind="{ loading }" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <view-section-card title="상품 상세설명" v-bind="{ loading }">
                                <naver-smarteditor v-model="product.content" rows="10" class="mb-n2" />
                            </view-section-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-btn fixed right bottom large fab color="primary" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

import Draggable from "vuedraggable";
import ShopProductSeo from "@/components/console/shop/products/shop-product-seo.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ShopProductBonus from "@/components/console/shop/products/shop-product-bonus.vue";
import ShopProductOptions from "@/components/console/shop/products/options/shop-product-options.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";
import ShopProductIslands from "@/components/console/shop/products/shop-product-islands.vue";
import ShopProductDiscount from "@/components/console/shop/products/shop-product-discount.vue";
import ShopProductSupplies from "@/components/console/shop/products/shop-product-supplies.vue";
import ShopProductShippings from "@/components/console/shop/products/shop-product-shippings.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ImageInput from "@/components/console/dumb/image-input.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

export default {
    components: {
        Draggable,
        ShopProductSeo,
        NaverSmarteditor,
        ShopProductBonus,
        ShopProductOptions,
        ShopCategorySelect,
        ShopProductIslands,
        ShopProductDiscount,
        ShopProductSupplies,
        ShopProductShippings,
        ListHeading,
        ImageInput,
        ImagesInput,
        ViewSectionCard,
        ViewSectionTitle,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        ///////////////////////////////////////////////
        // data
        ///////////////////////////////////////////////
        product: {
            name: null,
            code: null,
            price: 0,
            cost: 0,
            stock: 0,
            sequence: 0,
            hashtags: [],
            thumb: null,
            images: [],
            content: null,

            display: true,
            sale: true,
            best: false,
            newly: false,
            special: false,

            meta: {
                reviews: {
                    keywords: [],
                },
            },
        },

        brands: [],
        categories: [],

        ///////////////////////////////////////////////
        // states
        ///////////////////////////////////////////////
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._product;
        },
        isCopy() {
            return this.$route.query.mode == "copy";
        },
        optionsStock() {
            var stock = 0;
            for (var i in this.product.options) {
                stock += this.product.options[i].stock;
            }
            return stock;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        optionsStock() {
            if (this.product.optionsEnabled) {
                this.product.stock = this.optionsStock;
            }
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { categories } = await api.console.shop.categories.getCategories();
                this.categories = categories;

                var { brands } = await api.console.shop.brands.gets();
                this.brands = brands;

                if (!this.isCreate) {
                    var { product } = await api.console.shop.products.getProduct({ _id: this._product });

                    if (this.isCopy) {
                        product._id = undefined;

                        for (var i in product.options) {
                            delete product.options[i]._id;
                        }
                        for (var i in this.product.supplies) {
                            delete product.supplies[i]._id;
                        }
                    }

                    this.product = product;
                }

                if (this.isCopy) {
                    var { summary } = await api.console.shop.products.getProducts({ headers: { limit: 1 } });
                    this.product.sequence = summary.totalCount;
                }
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.product.optionsEnabled = 0 < (this.product.options || []).length;
                this.product.suppliesEnabled = 0 < (this.product.supplies || []).length;

                let { _thumb, thumb, _images = [], images = [], _options = [], options = [], optionsConf, ...form } = this.product;

                ////////////////////////////////////////////////////////////////
                // 옵션정보 이미지 저장
                ////////////////////////////////////////////////////////////////
                optionsConf.criteria = await Promise.all(
                    optionsConf.criteria.map(async (item) => ({
                        ...item,
                        values: await Promise.all(
                            item.values.map(async (value) => {
                                if (value.img instanceof File) {
                                    value.img = (await api.console.files.post({ path: "shop-colors" }, value.img))?.file;
                                }

                                value._img = value?.img?._id;
                                if (!value._img) delete value._img;

                                delete value.img;
                                return value;
                            })
                        ),
                    }))
                );
                const map__optionColors = new Map(optionsConf.criteria.flatMap(({ values }) => values.map((color) => [color.tempId, color])));

                ////////////////////////////////////////////////////////////////
                // 상품 저장
                ////////////////////////////////////////////////////////////////
                const { postProduct, putProduct } = api.console.shop.products;
                let { product } = await (this.isCreate || this.isCopy ? postProduct : putProduct)({ ...form, optionsConf });

                ////////////////////////////////////////////////////////////////
                // 옵션상품 저장
                ////////////////////////////////////////////////////////////////
                const { post: postOption, put: putOption } = api.console.shop.products.options;
                for (var i in options) {
                    let form = options[i];
                    form._product = product?._id;
                    form.color = map__optionColors.get(form.tempId__color);

                    options[i] = (await (form._id ? putOption : postOption)(form))?.option;
                }
                _options = options.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 추가상품 저장
                ////////////////////////////////////////////////////////////////
                for (var i in this.product.supplies) {
                    var supply = this.product.supplies[i];

                    if (supply.deleted) {
                        await api.console.shop.products.supplies.deleteSupply(product._id, supply);
                    } else if (supply._id) {
                        await api.console.shop.products.supplies.putSupply(product._id, supply);
                    } else if (!supply._id) {
                        await api.console.shop.products.supplies.postSupply(product._id, supply);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 썸네일 저장 / 삭제(X)
                ////////////////////////////////////////////////////////////////
                // 기존 구매내역 썸네일 유지를 위해 삭제 미진행
                ////////////////////////////////////////////////////////////////
                if (thumb instanceof File) thumb = (await api.console.files.post({ _product: product._id, index: "thumb" }, thumb))?.file;
                // if (_thumb && _thumb != thumb?._id) await api.console.files.delete({ _id: _thumb });
                _thumb = thumb?._id;

                ////////////////////////////////////////////////////////////////
                // 이미지 저장 / 삭제
                ////////////////////////////////////////////////////////////////
                for (const i in images) {
                    if (images[i] instanceof File) images[i] = (await api.console.files.post({ _product: product._id, index: `image-${i}` }, images[i]))?.file;
                }
                for (const i in _images) {
                    if (_images[i] && !images.some(({ _id }) => _id == _images[i])) await api.console.files.delete({ _id: _images[i] });
                }
                _images = images.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 상품 업데이트
                ////////////////////////////////////////////////////////////////
                product = await putProduct({ _id: product._id, _thumb, _images, _options });

                alert(this.isCreate || this.isCopy ? "등록 되었습니다" : "수정 되었습니다");

                this.$router.go(-1);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

