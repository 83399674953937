<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="720" width="100%" class="px-3 mx-n3">
            <list-heading title="운영정보설정" />

            <v-data-table :headers="deliveryHeaders" :items="deliveries" class="v-sheet--outlined">
                <template #top>
                    <v-card-title class="subtitle-2 font-weight-bold"> 운송업체설정 </v-card-title>
                    <v-divider />
                </template>
                <template #[`item.url`]="{ item }">
                    <v-text-field v-model="item.url" placeholder="ex) https://example.com?deliveryCode=" dense hide-details @input="saveDelivery(item)" />
                </template>
            </v-data-table>

            <v-radio-group v-model="setting.shop._shipping" dense class="pa-0 mt-3" hide-details @change="saveSetting">
                <v-data-table :headers="shippingsHeaders" :items="shippings" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                    <template #top>
                        <v-card-title class="subtitle-2 font-weight-bold">배송정책</v-card-title>
                        <v-divider />
                    </template>
                    <template #[`item.shipping`]="{ item }">
                        <v-radio :label="item.name" :value="item._id">
                            <template #label>
                                <v-icon class="mx-2" small :color="item.color">{{ item.icon }}</v-icon
                                ><span class="subtitle-2">{{ item.name }}</span>
                            </template>
                        </v-radio>
                    </template>
                    <template #[`item.config`]="{ item }">
                        <v-row v-if="item.code == 'product'" align="center">
                            <v-col cols="" md="4">
                                <v-currency-field v-model="item.price" label="배송비" suffix="원" persistent-placeholder outlined dense hide-details @input="saveShipping(item)" />
                            </v-col>
                        </v-row>
                        <v-row v-if="item.code == 'order'" align="center" class="mx-n2">
                            <v-col cols="" md="4" class="pa-2">
                                <v-currency-field v-model="item.range.basePrice" label="주문금액" suffix="원" outlined dense hide-details persistent-placeholder @input="saveShipping(item)" />
                            </v-col>
                            <v-col cols="" md="8" class="pa-2 py-0">
                                <v-row align="center" class="mx-n2">
                                    <v-col cols="4" md="4" class="pa-2 text-center">이상 구매시</v-col>
                                    <v-col cols="8" md="8" class="pa-2">
                                        <v-currency-field v-model="item.range.highPrice" label="배송비" suffix="원" outlined dense hide-details persistent-placeholder @input="saveShipping(item)" />
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="mx-n2">
                                    <v-col cols="4" md="4" class="pa-2 text-center">미만 구매시</v-col>
                                    <v-col cols="8" md="8" class="pa-2">
                                        <v-currency-field v-model="item.range.lowPrice" label="배송비" suffix="원" outlined dense hide-details persistent-placeholder @input="saveShipping(item)" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.code == 'free'">
                            <v-col class="py-0 text-start">※ 해당 제품 구매시 무료배송으로 표시됩니다.</v-col>
                        </v-row>
                        <v-row v-if="item.code == 'later'">
                            <v-col class="py-0 text-start">※ 해당 제품 구매시 착불배송으로 표시됩니다.</v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-radio-group>

            <v-card outlined class="mt-3" style="overflow:hidden">
                <v-card-title class="subtitle-2 font-weight-bold">도서산간설정</v-card-title>
                <v-divider />
                <v-tabs v-model="tab">
                    <v-tab v-for="island in islands" :key="island.code">{{ island.name }}</v-tab>
                    <v-tab-item v-for="island in islands" :key="island.code">
                        <v-divider />
                        <v-data-table :headers="islandHeaders" :items="island.areas" disable-sort>
                            <template #[`item.createdAt`]="{ item }">
                                {{ item.createdAt.toDateTime() }}
                            </template>
                            <template #[`item.actions`]="{ item }">
                                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs>
            </v-card>

            <!-- <v-card outlined class="mt-3">
                <v-card-title class="subtitle-2 font-weight-bold">
                    <v-row align="center">
                        <v-col cols="auto py-0">SMTP(이메일 전송 설정)</v-col>
                        <v-col class="primary--text caption py-0 pl-0">
                            "Google 계정 관리 -> 보안 -> 보안 수준이 낮은 앱의 액세스" 를 사용으로 변경
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="setting.smtp.username" label="발신자 아이디(gmail)" persistent-placeholder dense hide-details @input="saveSetting" />
                    <v-text-field v-model="setting.smtp.password" label="비밀번호" persistent-placeholder dense hide-details class="mt-6" @input="saveSetting" />
                </v-card-text>
            </v-card> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import listHeading from "@/components/console/dumb/list-heading.vue";

const initSetting = (setting = {}) => ({
    ...setting,
    smtp: {
        ...(setting?.smtp || {}),
        username: setting?.smtp?.username || null,
        password: setting?.smtp?.password || null,
    },
});

export default {
    components: { listHeading },
    data: () => ({
        ready: false,

        setting: initSetting(),

        ///////////////////////////////////////////////
        // 운송업체설정
        ///////////////////////////////////////////////
        deliveries: [],
        deliveryHeaders: [
            { text: "업체명", value: "name", width: 210 },
            { text: "URL", value: "url" },
        ],

        ///////////////////////////////////////////////
        // 도서산간
        ///////////////////////////////////////////////
        tab: 0,
        islands: [],
        islandHeaders: [
            { text: "우편번호", value: "postcode", width: 140 },
            { text: "도로명주소", value: "address" },
        ],

        ///////////////////////////////////////////////
        // 배송정책
        ///////////////////////////////////////////////
        shippings: [],
        shippingsHeaders: [
            { text: "정책", value: "shipping", width: 200 },
            { text: "", value: "config" },
        ],
    }),
    created() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { deliveries } = await api.console.shop.deliveries.getDeliveries();
                this.deliveries = deliveries;

                var { islands } = await api.console.shop.islands.getIslands();
                this.islands = islands;

                var { shippings } = await api.console.shop.shippings.getShippings();
                this.shippings = shippings;

                var { setting } = await api.console.setting.get();
                this.setting = initSetting(setting);

                this.ready = true;
            } catch (error) {
                if (error.response) alert(error.response.data.message);
                console.error(error);
            }
        },

        async saveDelivery(delivery) {
            try {
                await api.console.shop.deliveries.putDelivery(delivery);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async saveShipping(shipping) {
            try {
                await api.console.shop.shippings.putShipping(shipping);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async saveSetting() {
            try {
                await api.console.setting.putSetting(this.setting);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-row {
        height: auto !important;
        max-height: auto !important;
        min-height: auto !important;

        &:first-of-type {
            padding-top: 12px;
        }
        &:last-of-type {
            padding-bottom: 12px;
        }
    }
    .v-data-table__mobile-row__header {
        flex: 0 0 25%;
    }
}
</style>
