<template>
    <v-layout justify-center>
        <v-responsive max-width="1024" width="100%" height="100%">
            <v-row class="mt-4 px-1" align="center">
                <v-col cols="auto" class="tit tit--lg">
                    <span v-if="$route.params._ip">IP 상세</span>
                    <span v-else>IP 생성</span>
                </v-col>
                <v-spacer/>
            </v-row>
        
            <v-card class="mx-1 mt-3">
                <v-card-title class="tit tit--xs">IP정보</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="blackIp.name" label="이름" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="blackIp.ip" label="IPv4 address"  placeholder="예) 127.0.0.1" :rules="rules" persistent-placeholder></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="blackIp.phone" label="연락처" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="blackIp.reason" label="사유" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions />
            </v-card>
        
            <v-card class="mt-4 mx-1">
                <v-card-title class="tit tit--xs font-weight-bold">관리자 메모</v-card-title>
                <v-card-text>
                    <v-textarea v-model="blackIp.note" outlined hide-details rows="2" />
                </v-card-text>
            </v-card>
        
            <v-row class="my-6">
                <v-spacer/>
                <v-col cols="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
                <v-spacer/>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        VPasswordField
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            blackIp: {
                ip: null,
                name: null,
                code: "black",
                phone: null,
                reason: null,
                note: null,
            },

            rules: [value => this.ValidateIPaddress(value) || "올바른 IP를 입력 하세요"]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._ip){
                let { ipitem } = await api.console.ip.get({ _id: this.$route.params._ip });
                this.blackIp = {
                    ...this.blackIp,
                    ...ipitem
                };
            }
        },

        async save(){
            this.blackIp._id
            ? await api.console.ip.put({
                ...this.blackIp
            })
            : await api.console.ip.post({
                ...this.blackIp
            });

            alert("저장되었습니다");
            this.$router.push(`/dhbizadm/blacklist`);
        }, 
        ValidateIPaddress(value) {  
            let ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            if(ipformat.test(value)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>