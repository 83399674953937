var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "400"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.purchase ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("메모")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.purchase.note,
      callback: function ($$v) {
        _vm.$set(_vm.purchase, "note", $$v);
      },
      expression: "purchase.note"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("확인")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }