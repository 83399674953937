<template>
    <v-expand-transition>
        <v-card v-show="showsSearch" flat outlined>
            <v-card-title class="subtitle-2 font-weight-bold">{{ title }}</v-card-title>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-card-actions v-if="!hideAction">
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded text @click="$emit('search')"> <v-icon small>mdi-magnify</v-icon> 검색 </v-btn>
            </v-card-actions>
        </v-card>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "검색필터" },
        hideAction: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
};
</script>

<style></style>
