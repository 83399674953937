<template>
    <view-section-card title="배송설정 (사용/미사용)" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="shippingEnabled" :value="true" v-bind="attrs_switch" @change="input" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="shippingEnabled">
                <v-divider />
                <v-card-text>
                    <v-radio-group v-model="$data._shipping" dense class="mt-0" @change="input()">
                        <v-data-table :headers="shippingsHeaders" :items="shippings" disable-sort disable-pagination hide-default-footer>
                            <template v-slot:item.shipping="{ item }">
                                <v-radio :label="item.name" :value="item._id">
                                    <template v-slot:label>
                                        <v-icon class="mx-2" small :color="item.color">{{ item.icon }}</v-icon
                                        ><span class="subtitle-2">{{ item.name }}</span>
                                    </template>
                                </v-radio>
                            </template>
                            <template v-slot:item.config="{ item }">
                                <v-row v-if="item.code == 'product'" align="center">
                                    <v-col cols="auto">
                                        <v-text-field
                                            v-model="item.price"
                                            label="배송비(원)"
                                            persistent-placeholder
                                            outlined
                                            dense
                                            hide-details
                                            @input="
                                                item.price = item.price.replace(/[^0-9]/, '');
                                                input();
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="item.code == 'order'">
                                    <v-col cols="auto">
                                        {{ item.range.basePrice.format() }}
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-row align="center">
                                            <v-col cols="auto">이상 구매시</v-col>
                                            <v-col cols="auto">
                                                {{ item.range.highPrice.format() }}
                                            </v-col>
                                        </v-row>
                                        <v-row align="center">
                                            <v-col cols="auto">미만 구매시</v-col>
                                            <v-col cols="auto">
                                                {{ item.range.lowPrice.format() }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-if="item.code == 'free'">
                                    <v-col class="py-0 text-start">※ 해당 제품 구매시 무료배송으로 표시됩니다.</v-col>
                                </v-row>
                                <v-row v-if="item.code == 'later'">
                                    <v-col class="py-0 text-start">※ 해당 제품 구매시 착불배송으로 표시됩니다.</v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-radio-group>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>


<script>
import { attrs_switch } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {
            _shipping: null,
            shippingEnabled: false,

            setting: {},

            shippings: [],
            shippingsHeaders: [
                {
                    text: "기본정책",
                    align: "center",
                    width: 200,
                    value: "shipping",
                },
                {
                    text: "설정",
                    align: "center",
                    value: "config",
                },
            ],

            attrs_switch,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getShippings();
                await this.getSetting();

                var { _shipping, shippingEnabled } = this.value;

                Object.assign(this.$data, {
                    _shipping: _shipping || this.shippings.find((shipping) => shipping._id == (this.setting.shop._shipping || this.shippings[0]._id))._id,
                    shippingEnabled: shippingEnabled || false,
                });

                for (var shipping of this.shippings) {
                    if (this.$data._shipping == shipping._id) {
                        Object.assign(shipping, this.value.shipping || {});
                    }
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        input() {
            this.value._shipping = this.$data._shipping;
            this.value.shipping = this.shippings.find((shipping) => shipping._id == this.$data._shipping);
            this.value.shippingEnabled = this.shippingEnabled;

            this.$emit("input", { ...this.value });
        },
        getShippings() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get(`/api/console/shop/shippings`);

                    this.shippings = res.data.shippings;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getSetting() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/setting");

                    this.setting = res.data.setting;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
